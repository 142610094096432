import React, { useMemo, useRef } from "react";
import JoditEditor from "jodit-react";

function JoditEditorCustom({ onChange, value, height }) {
  let editor = useRef();
  const copyStringToClipboard = function (str) {
    var el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  const facilityMergeFields = [
    "FacilityNumber",
    "FacilityName",
    "Address",
    "MapCategory",
    "Latitude",
    "Longitude",
    "ReceivingPlant",
    "TrunkLine",
    "SiteElevation",
  ];
  const inspectionMergeFields = [
    "InspectionCompleteDate",
    "InspectionEventType",
  ];
  const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
    let optionGroupElement = document.createElement("optgroup");
    optionGroupElement.setAttribute("label", optionGrouplabel);
    for (let index = 0; index < mergeFields.length; index++) {
      let optionElement = document.createElement("option");
      optionElement.setAttribute("class", "merge-field-select-option");
      optionElement.setAttribute("value", mergeFields[index]);
      optionElement.text = mergeFields[index];
      optionGroupElement.appendChild(optionElement);
    }
    return optionGroupElement;
  };
  const buttons = [
    "undo",
    "redo",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "align",
    "|",
    "ul",
    "ol",
    "font",
    "fontsize",
    "|",
    "hr",
    "copyformat",
    "|",
    "fullsize",
    "selectall",
    "source",
  ];

  const editorConfig = useMemo(
    () => ({
      readonly: false, // Toolbar stays active for styling
      toolbar: true, // Enable toolbar
      enableDragAndDropFileToEditor: false, // Prevent drag-and-drop files
      events: {
        beforeCommand: (command) => {
          if (command === "insertHTML" || command === "change") {
            return false; // Disable editing commands
          }
        },
        // Prevent keyboard input
        keydown: (e) => {
          e.preventDefault();
        },
      },

      //defaultActionOnPaste: "insert_clear_html",
      buttons: buttons,
      // uploader: {
      //   insertImageAsBase64URI: true,
      // },
      // width: "100%",
      // height: 300,
    }),
    []
  );
  return (
    <JoditEditor
      ref={editor}
      value={value}
      // value={editorBody}
      config={editorConfig}
      onBlur={onChange}
    />
  );
}

export default JoditEditorCustom;
