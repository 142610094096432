import React, { useEffect, useRef, useState } from "react";
import MainScreen from "../../components/Navbar/MainScreen";
import ContactImport from "../../assets/icons/contact-import.svg";
import campaignImport from "../../assets/icons/campaign-import.svg";
import emailImport from "../../assets/images/Emails-bro.svg";
import deleteImage from "../../assets/icons/delete.png";
import leftArrow from "../../assets/icons/left-arrow.png";
import rightArrow from "../../assets/icons/right-arrow.png";
import "./CampaignDetail.css";
import { useLocation, useNavigate } from "react-router-dom";
import CampaignIcon from "@mui/icons-material/Campaign";
import EmailCampaignCreated from "../../assets/images/Email-campaign-created.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  Box,
  Card,
  Chip,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  styled,
  SwipeableDrawer,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import CustomInput from "../../components/CustomInput/CustomInput";
import EmojiPicker from "emoji-picker-react";
import EmojiAdd from "../../assets/icons/emoji-round-plus-svgrepo-com.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import toast, { Toaster } from "react-hot-toast";
import AXIOS from "../../utils/AXIOS";
import ReactDataTable from "../../components/ReactDataTable/ReactDataTable";
import { connect } from "react-redux";
import Lottie from "react-lottie-player";
import confettiAnime from "../../assets/lottieFiles/confetti.json";
import { Tooltip as TooltipMaterial } from "@mui/material";
import sendEmailIcon from "../../assets/icons/send-email.svg";
import previewEmailIcon from "../../assets/icons/preview.svg";
import mailBoxIcon from "../../assets/icons/mail-box.svg";
import { RxCross1 } from "react-icons/rx";
import InfoIcon from "@mui/icons-material/Info";
import { TabPanel } from "@mui/lab";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  Filler,
} from "chart.js";
import { Bar, Doughnut, Pie, Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { LineElement } from "chart.js";
import { IconContext } from "react-icons";
import { FaArrowLeftLong } from "react-icons/fa6";
import CustomDropdown from "../../components/CustomDropDown/CustomDropDown";
import {
  campaignStatus,
  deliveryStatusArr,
  formatDateToDDMMYYYY,
  formatTime,
} from "../../utils/Constants";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Spinner from "../../components/Spinner/Spinner";
import { COLORS } from "../../constants/theme";
import JoditEditorCustom from "../../components/JoditEditorCustom/JoditEditorCustom";
ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend,
  Filler,
  ChartDataLabels,
  ArcElement,
  BarElement
);

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    variant="scrollable"
    scrollButtons="auto"
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#fff",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);
function CampaignDetail(props) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getEmailContent();
  }, []);

  const queryParams = new URLSearchParams(location.search);

  const [customGreetings, setCustomGreetings] = useState({
    CUSTOM_GREETING_NAME: "",
    CUSTOM_GREETING_NAME_ERROR: false,
  });

  const [campaignGreetingData, setCampaignGreetingData] = useState({
    CAMPAIGN_GREETINGS_HTML: "",
    CAMPAIGN_LOADING: false,
  });

  console.log("Asdljasdjasdsad", campaignGreetingData);

  const [DroppableItems, setDroppableItems] = useState([
    // { id: "1", content: "Draggable Item 1" },
    // { id: "2", content: "Draggable Item 2" },
    // { id: "3", content: "Draggable Item 3" },
    { content: "Hi", id: "Hi" },
    { content: "Hello", id: "Hello" },
    { content: "Hey", id: "Hey" },
    { content: "Dear", id: "Dear" },
    { content: "Greetings", id: "Greetings" },
    { content: "Warm greetings", id: "Warm greeting" },
    { content: "My dear ", id: "My dear" },
    { content: "Dearest", id: "Dearest" },
    { content: "Lovely", id: "Lovely" },
    { content: "Hi friend", id: "Hi friend" },
    { content: "Hello my friend", id: "Hello my friend" },
    { content: "Dear Sir/Madam", id: "Dear Sir/Madam" },
    { content: "Good day", id: "Good day" },
    { content: "Congratulations", id: "Congratulations" },
    { content: "Welcome", id: "Welcome" },
    { content: "Woohoo! Hi", id: "Woohoo ! Hi" },
    { content: "Namaste", id: "Namaste" },
    { content: "Hola", id: "Hola" },
    { content: "Good Morning", id: "Good Morning" },
    { content: "Good Afternoon", id: "Good Afternoon" },
    { content: "Good Evening", id: "Good Evening" },
    { content: "Hey there", id: "Hey there" },
    { content: "Hiya", id: "Hiya" },
    { content: "Howdy", id: "Howdy" },
    { content: "Yo", id: "Yo" },
    { content: "What’s up", id: "What’s up" },
    { content: "Hi team", id: "Hi team" },
    { content: "Hello folks", id: "Hello folks" },
    { content: "Hey everyone", id: "Hey everyone" },
    { content: "Mr.", id: "Mr." },
    { content: "Miss.", id: "Miss." },
    { content: "Name", id: "{{Name}}" },
    // { content: "Middle Name", id: "{{MiddleName}}" },
    // { content: "Last Name", id: "{{LastName}}" },
  ]);

  const [droppedItems, setDroppedItems] = useState([]);

  console.log("Adlkasjdasdas", droppedItems);

  useEffect(() => {
    if (droppedItems.length > 0) {
      setCampaignGreetingData((prev) => ({
        ...prev,
        CAMPAIGN_GREETINGS_HTML: `<p>${droppedItems.map((val) => val.id).join(" ")}</p>`,
      }));
    }
  }, [droppedItems]);

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return; // If dropped outside any droppable area, do nothing.

    if (
      source.droppableId === "draggable-list" &&
      destination.droppableId === "droppable-container"
    ) {
      // Remove item from draggable list
      const updatedItems = Array.from(DroppableItems);
      const [movedItem] = updatedItems.splice(source.index, 1);
      setDroppableItems(updatedItems);

      // Add item to droppable container
      const updatedDroppedItems = Array.from(droppedItems);
      updatedDroppedItems.splice(destination.index, 0, movedItem);
      setDroppedItems(updatedDroppedItems);
    } else if (
      source.droppableId === "droppable-container" &&
      destination.droppableId === "droppable-container"
    ) {
      // Rearrange within the droppable container
      const updatedDroppedItems = Array.from(droppedItems);
      const [movedItem] = updatedDroppedItems.splice(source.index, 1);
      updatedDroppedItems.splice(destination.index, 0, movedItem);
      setDroppedItems(updatedDroppedItems);
    }
  };

  const handleRemoveFromContainer = (item) => {
    // Remove the item from the droppable container
    const updatedDroppedItems = droppedItems.filter(
      (droppedItem) => droppedItem.id !== item.id
    );
    setDroppedItems(updatedDroppedItems);

    // Add it back to the draggable items list
    setDroppableItems([...DroppableItems, item]);
  };
  const [subCampaignOptions, setSubCampaignOptions] = useState([
    {
      label: "Target All Contacts",
      value: "1",
      TOTAL_COUNT: 0,
    },
    {
      label: "Create for All Delivered Emails",
      value: "2",
      TOTAL_COUNT: 0,
    },

    {
      label: "Create for opened Emails",
      value: "4",
      TOTAL_COUNT: 0,
    },
    {
      label: "Create for Not opened Email",
      value: "5",
      TOTAL_COUNT: 0,
    },
  ]);

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  useEffect(() => {
    // Sync tab state with query string if URL changes
    const tabFromUrl = queryParams.get("tab");
    if (tabFromUrl && parseInt(tabFromUrl, 10) !== ReportSelectTab) {
      setReportSelectTab(parseInt(tabFromUrl, 10));
    }
  }, [location.search]);

  const [emailContentData, setEmailContentData] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);

  const [showCampaignDetailsPage, setShowCampaignDetailsPage] = useState({
    STATUS: null,
    STATUS_CODE: "",
  });

  const [transactionFlags, setTransactionFlags] = useState({
    ADD_RECIPIENT_LOADING: false,
    SEND_TEST_EMAIL_LOADING: false,
    SEND_EMAIL_LOADING: false,
    FROM_NAME_SAVE_LOADING: false,
    SUBJECT_SAVE_LOADING: false,
    PERSONALIZATION_SAVE_LOADING: false,
  });

  const [sendTestEmailQuery, setSendTestEmailQuery] = useState({
    EMAILS: "",
    EMAIL_ERROR: false,
    EMAIL_SENT: false,
  });

  const getEmailContent = () => {
    fetch(AXIOS.defaultPort + AXIOS.getTemplateByCampaignId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CAMPAIGN_ID: location.state.campaign_id,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        let tempCampaignSubjectData = {
          SUBJECT: "",
          PREVIEW_TEXT: "",
          SUBJECT_SET: true,
          FROM_NAME: "",
          FROM_NAME_DEFAULT: "",
          ATTACHMENT: [],
        };

        if (data.length > 0) {
          if (data[0].TEMPLATE_HTML) {
            handlePreview(AXIOS.defaultPort + data[0].TEMPLATE_HTML);
          }
          console.log("ASdaskdklsadsa", data);

          setEmailContentData(data[0]);
          tempCampaignSubjectData = {
            SUBJECT: data[0]?.SUBJECT || "",
            PREVIEW_TEXT: data[0]?.PREVIEW_TEXT || "",
            SUBJECT_SET: true,
            FROM_NAME: data[0]?.FROM_NAME || "",
            FROM_NAME_DEFAULT: data[0]?.FROM_NAME || "",
            ATTACHMENT: [],
          };
          if (data[0]?.FROM_EMAIL) {
            tempCampaignSubjectData.FROM_EMAIL_DEFAULT = data[0]?.FROM_EMAIL;
            tempCampaignSubjectData.FROM_EMAIL = data[0]?.FROM_EMAIL;
          }
        }

        setCampaignSubjectData((prev) => ({
          ...prev,
          ...tempCampaignSubjectData,
        }));
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const [recipientFile, setRecipientFile] = useState("");

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setRecipientFile(file);
    }
  };

  const createSubCampaign = () => {
    if (createCampaignData.CAMPAIGN_NAME == "") {
      setCreateCampaignData((prev) => ({
        ...prev,
        CAMPAIGN_NAME_ERROR: true,
      }));
    } else {
      fetch(AXIOS.defaultPort + AXIOS.createSubCampaign, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          CAMPAIGN_NAME: createCampaignData.CAMPAIGN_NAME,
          CAMPAIGN_DESCRIPTION: createCampaignData.CAMPAIGN_DESCRIPTION,
          FROM_CAMPAIGN: location.state.campaign_id,
          RECIPIENT_TYPE: createCampaignData?.SELECTED_RECIPIENT_TYPE,
          CREATED_BY: props.auth_id,
        }),
      })
        .then(async (response) => {
          console.log("asdjnsajkdasdsa", response);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          console.log("Adkjnasdjnkas", data);
          setCreateCampaignData({
            CAMPAIGN_NAME: "",
            CAMPAIGN_DESCRIPTION: "",
            CAMPAIGN_NAME_ERROR: false,
            CAMPAIGN_DESCRIPTION_ERROR: false,
            CAMPAIGN_ID: data?.campaignId,
            CAMPAIGN_COMPLETED: true,
          });
          toast.success("Campaign Created");
        })
        .catch((err) => {
          console.log("asdjnsajkdasdsa", err);

          toast.error("Something went wrong");
        });
    }
  };

  const [SubjectDrawerState, setSubjectDrawerState] = useState(false);
  const [PersonalizationDrawerState, setPersonalizationDrawerState] =
    useState(false);
  const [FromDrawerState, setFromDrawerState] = useState(false);
  const [CampaignSendDrawerState, setCampaignSendDrawerState] = useState(false);
  const [TestEmailDrawerState, setTestEmailDrawerState] = useState(false);
  const [recipientsDrawerState, setRecipientsDrawerState] = useState(false);

  const [ReportSelectTab, setReportSelectTab] = useState(0);

  const [campaignSubjectData, setCampaignSubjectData] = useState({
    SUBJECT: "",
    PREVIEW_TEXT: "",
    SUBJECT_SET: null,
    FROM_EMAIL: "DoNotReply@betacodeinfotech.com",
    FROM_EMAIL_DEFAULT: "DoNotReply@betacodeinfotech.com",
    FROM_NAME: "",
    FROM_NAME_DEFAULT: "",
    ATTACHMENT: [],
  });
  const [createCampaignData, setCreateCampaignData] = useState({
    CAMPAIGN_NAME: "",
    CAMPAIGN_DESCRIPTION: "",
    CAMPAIGN_NAME_ERROR: false,
    CAMPAIGN_DESCRIPTION_ERROR: false,
    CAMPAIGN_COMPLETED: false,
    SELECTED_RECIPIENT_TYPE: "",
  });

  const [campaignCreateModal, setCampaignCreateModal] = useState(false);

  const [campaignName, setCampaignName] = useState({
    NAME: "",
    DEFAULT_NAME: "",
    EDIT_FLAG: false,
  });

  const [campaignData, setCampaignData] = useState();
  const [campaignReportData, setCampaignReportData] = useState({
    DELIVERED_COUNT: 0,
    DELIVERED_PERCENT: null,
    OPENED_COUNT: 0,
    OPENED_PERCENT: null,
    RECIPIENT_COUNT: 0,
  });

  const [recipientStep, setRecipientStep] = useState("");
  const [recipientImportOptions, setRecipientImportOptions] = useState([
    {
      TITLE: "Upload a file",
      DeSCRIPTION: (
        <p>
          Use <strong>CSV</strong> or tab-delimited <strong>TXT</strong> files.
          Make sure files are well-formatted and the data is clean.
        </p>
      ),
      IMAGE: ContactImport,
      MARGIN: 60,
      onClick: () => {
        setRecipientStep("UPLOAD_FILE");
      },
    },
    {
      TITLE: "From Another Campaign ",
      DeSCRIPTION: (
        <p>
          Quickly import your contacts to grow your audience and start sending
          emails in no time.
        </p>
      ),
      IMAGE: campaignImport,
      // MARGIN: 30,
      onClick: () => {
        toast.success("Feature coming soon...");
        // setRecipientStep("ANOTHER_CAMPAIGN");
      },
    },
    {
      TITLE: "From Existing Contacts ",
      DeSCRIPTION: (
        <p>
          Quickly import your contacts to grow your audience and start sending
          emails in no time.
        </p>
      ),
      IMAGE: emailImport,
      // MARGIN: 0,
      onClick: () => {
        toast.success("Feature coming soon...");

        // setRecipientStep("EXISTING_CONTACTS");
      },
    },
  ]);

  const recipientTableColumns = [
    {
      name: "Name",
      selector: (val) => val.NAME,
      sortable: true,
    },
    {
      name: "Email ID",
      selector: (val) => val.EMAIL,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (val) => val.MOBILE,
      sortable: true,
    },
    {
      name: "Company",
      selector: (val) => val.COMPANY,
      sortable: true,
    },
    {
      name: "Designation",
      selector: (val) => val.DESIGNATION,
      sortable: true,
    },
    {
      name: "Action",
      selector: (val) => val.DESIGNATION,
      sortable: false,
      cell: (val) => {
        return (
          <div className="delete-button">
            <img
              src={deleteImage}
              style={{
                width: 20,
                height: 20,
              }}
              onClick={() => {
                deleteRecipient([val]);
              }}
            />
          </div>
        );
      },
    },
  ];

  const deleteRecipient = async (RecipientData) => {
    console.log("Asdasdad", RecipientData);

    let payloadRecipient = [];
    RecipientData.map((val) => {
      payloadRecipient.push({
        _id: val._id,
      });
    });

    await fetch(AXIOS.defaultPort + AXIOS.deleteRecipient, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        RECIPIENTS: payloadRecipient,
        CAMPAIGN_ID: location.state.campaign_id,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        toast.success("Recipient Deleted");
        getRecipientData();
      })
      .catch((err) => {});
  };

  const recipientCampaignColumns = [
    {
      name: "Name",
      selector: (val) => val.NAME,
      sortable: true,
    },
    {
      name: "Email ID",
      selector: (val) => val.EMAIL,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (val) => val.MOBILE,
      sortable: true,
    },
    {
      name: "Company",
      selector: (val) => val.COMPANY,
      sortable: true,
    },
    {
      name: "Designation",
      selector: (val) => val.DESIGNATION,
      sortable: true,
    },
    {
      name: "Email Delivered",
      selector: (val) => val.DELIVERED,
      sortable: true,
      width: "140px",
      cell: (val) => {
        return <span>{val.DELIVERED == true ? "Yes" : "No"}</span>;
      },
    },
    {
      name: "Open",
      selector: (val) => val.OPENED,
      sortable: true,
      cell: (val) => {
        return <span>{val.OPENED == true ? "Yes" : "No"}</span>;
      },
    },
    {
      name: "Delivery time",
      selector: (val) => val.DELIVERED_TIME,
      sortable: true,
      width: "170px",
      cell: (val) => {
        return (
          <>
            <span>
              {/* {val.DELIVERED_TIME
                ? new Date(val.DELIVERED_TIME).toDateString()
                : ""} */}
              {val.DELIVERED_TIME
                ? formatDateToDDMMYYYY(val.DELIVERED_TIME)
                : ""}{" "}
            </span>{" "}
            <span
              style={{
                marginLeft: 5,
              }}
            >
              {val.DELIVERED_TIME
                ? new Date(val.DELIVERED_TIME).toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: true,
                  })
                : "--"}
            </span>
          </>
        );
      },
    },

    {
      name: "Open time",
      selector: (val) => val.OPENED_TIME,
      sortable: true,
      width: "170px",
      cell: (val) => {
        return (
          <>
            <span>
              {val.OPENED_TIME ? formatDateToDDMMYYYY(val.OPENED_TIME) : ""}{" "}
            </span>
            <span
              style={{
                marginLeft: 5,
              }}
            >
              {val.OPENED_TIME
                ? new Date(val.OPENED_TIME).toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: true,
                  })
                : "--"}
            </span>
          </>
        );
      },
    },
  ];

  const [recipientData, setRecipientData] = useState(null);
  const [emailRecipientQuery, setEmailRecipientQuery] = useState({
    DELIVERY_STATUS: { label: "Show All", value: 1 },
  });

  useEffect(() => {
    if (location.state.campaign_id) {
      getRecipientData();
      getCampaignData();
      getCampaignReportCardData();
      getLast24HrPerformanceGraphData();
    }
  }, []);

  const [Last24HrPerformanceData, setLast24HrPerformanceData] = useState({
    LABELS: [],
    COUNT: [],
    COLOR: "rgb(91, 91, 240)",
    TITLE: "Opened",
  });
  const [Last24HrPerformanceQuery, setLast24HrPerformanceQuery] = useState({
    INTERVAL: { label: "2 Hours", value: 120 },
    EMAIL_STATUS: {
      label: "Opened",
      value: "OPENED",
    },
  });

  useEffect(() => {
    getLast24HrPerformanceGraphData();
  }, [Last24HrPerformanceQuery]);

  const getLast24HrPerformanceGraphData = async () => {
    await fetch(AXIOS.defaultPort + AXIOS.last24HrPerformanceGraph, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CAMPAIGN_ID: location.state.campaign_id,
        INTERVAL: Last24HrPerformanceQuery.INTERVAL.value,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const labels = data.map((item) => formatTime(item.START_TIME)); // Generate time labels
        const deliveredCounts = data.map(
          (item) => item.DELIVERED_COUNT_PERCENT
        ); // Extract DELIVERED_COUNT
        const openedCounts = data.map((item) => item.OPENED_COUNT_PERCENT); // Extract OPENED_COUNT
        const BouncedCounts = data.map((item) => item.BOUNCED_COUNT_PERCENT); // Extract OPENED_COUNT

        let transformedData = { COLOR: "", COUNT: [], TITLE: "" };
        if (Last24HrPerformanceQuery.EMAIL_STATUS.value == "OPENED") {
          transformedData.COUNT = openedCounts;
          transformedData.COLOR = "rgb(91, 91, 240)";
          transformedData.TITLE = "Opened";
        } else if (Last24HrPerformanceQuery.EMAIL_STATUS.value == "DELIVERY") {
          transformedData.COUNT = deliveredCounts;
          transformedData.COLOR = "rgb(0, 128, 0)";
          transformedData.TITLE = "Delivery";
        } else if (Last24HrPerformanceQuery.EMAIL_STATUS.value == "BOUNCED") {
          transformedData.COUNT = BouncedCounts;
          transformedData.COLOR = "rgb(139, 0, 0)";
          transformedData.TITLE = "Bounced";
        }
        setLast24HrPerformanceData({
          LABELS: labels,
          ...transformedData,
          // DELIVERY_COUNTS: deliveredCounts,
          // OPEN_COUNTS: openedCounts,
          // BOUNCED_COUNTS: BouncedCounts,
        });
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };
  const getCampaignReportCardData = async () => {
    await fetch(AXIOS.defaultPort + AXIOS.getCountsForCampaign, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CAMPAIGN_ID: location.state.campaign_id,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setSubCampaignOptions([
          {
            label: "Target All Contacts",
            value: "1",
            TOTAL_COUNT: data.RECIPIENT_COUNT || 0,
          },
          {
            label: "Create for All Delivered Emails",
            value: "2",
            TOTAL_COUNT: data.DELIVERED_COUNT || 0,
          },

          {
            label: "Create for opened Emails",
            value: "4",
            TOTAL_COUNT: data.OPENED_COUNT || 0,
          },
          {
            label: "Create for Not opened Email",
            value: "5",
            TOTAL_COUNT:
              data.DELIVERED_COUNT - data.OPENED_COUNT > 0
                ? data.DELIVERED_COUNT - data.OPENED_COUNT
                : 0,
          },
        ]);

        setCampaignAnalysis([
          {
            TITLE: "Delivered Emails",
            ID: "1",
            VALUE: data.DELIVERED_COUNT || 0,
            SHOW: true,
            HOVER_SHADOW: "",
          },
          {
            TITLE: "Delivered Emails %",
            VALUE: data.DELIVERED_PERCENT || "0.00 %",

            ID: "2",

            SHOW: true,
            HOVER_SHADOW: "",
          },
          {
            TITLE: "Opened Emails",
            VALUE: data.OPENED_COUNT || "0",
            ID: "3",
            SHOW: true,
            HOVER_SHADOW: "",
          },
          {
            TITLE: "Opened Emails %",
            VALUE: data.OPENED_PERCENT || "0.00 %",
            ID: "4",
            SHOW: true,
            HOVER_SHADOW: "",
          },
          {
            TITLE: "Recipients",
            VALUE: data.RECIPIENT_COUNT || "0.00 %",
            ID: "5",
            SHOW: true,
            HOVER_SHADOW: "",
          },
          {
            TITLE: "Pending Email",
            VALUE: data.PENDING_COUNT || "0",
            ID: "6",
            SHOW: true,
            HOVER_SHADOW: "",
          },
          {
            TITLE: "Bounced Emails",
            VALUE: data.BOUNCED_COUNT || "0",
            ID: "6",
            SHOW: true,
            HOVER_SHADOW: "",
          },
          {
            TITLE: "Bounced Emails %",
            VALUE: data.BOUNCED_PERCENT || "0.00",
            ID: "7",
            SHOW: true,
            HOVER_SHADOW: "",
          },
        ]);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const campaignSubjectUpdate = async (updatingPart) => {
    let campaignSubjectFormData = new FormData();

    if (updatingPart == "SUBJECT") {
      campaignSubjectFormData.append("SUBJECT", campaignSubjectData.SUBJECT);
      campaignSubjectFormData.append(
        "PREVIEW_TEXT",
        campaignSubjectData.PREVIEW_TEXT
      );
    } else if (updatingPart == "FROM_NAME") {
      campaignSubjectFormData.append(
        "FROM_NAME",
        campaignSubjectData.FROM_NAME
      );
      campaignSubjectFormData.append(
        "FROM_EMAIL",
        campaignSubjectData.FROM_EMAIL
      );
    } else if (updatingPart == "ATTACHMENT") {
      campaignSubjectData.ATTACHMENT.forEach((file, index) => {
        campaignSubjectFormData.append(`ATTACHMENT`, file);
      });
    }
    campaignSubjectFormData.append("CAMPAIGN_ID", location.state.campaign_id);
    campaignSubjectFormData.append("CREATED_BY", props.auth_id);
    setTransactionFlags((prev) => ({
      ...prev,
      SUBJECT_SAVE_LOADING: true,
    }));

    await fetch(AXIOS.defaultPort + AXIOS.createCampaignTemplate, {
      method: "POST",
      body: campaignSubjectFormData,
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Sdkjasndjkasdas", data);

        if (updatingPart == "SUBJECT") {
          toast.success("Campaign Subject Updated");
        } else if (updatingPart == "FROM_NAME") {
          toast.success("Campaign From Updated");
        } else if (updatingPart == "ATTACHMENT") {
          toast.success("Campaign Attachment Added");
        }
        setTransactionFlags((prev) => ({
          ...prev,
          SUBJECT_SAVE_LOADING: false,
        }));
        getEmailContent();
      })
      .catch((err) => {
        console.log("Sdkjasndjkasdas", err);

        setTransactionFlags((prev) => ({
          ...prev,
          SUBJECT_SAVE_LOADING: false,
        }));
      });
  };
  const campaignNameUpdate = async () => {
    await fetch(AXIOS.defaultPort + AXIOS.updateCampaign, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CAMPAIGN_NAME: campaignName.NAME,
        CAMPAIGN_ID: location.state.campaign_id,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        toast.success("Campaign Name Updated");
        getCampaignData();
      })
      .catch((err) => {});
  };
  const campaignPersonalizationUpdate = async () => {
    setTransactionFlags((prev) => ({
      ...prev,
      PERSONALIZATION_SAVE_LOADING: true,
    }));
    let tempCampaignPersonalizationArr = droppedItems.map((val) => val.id);
    console.log("ASdkjasdjkasdasd", {
      CAMPAIGN_GREETING: droppedItems,
      CAMPAIGN_GREETING_HTML: campaignGreetingData.CAMPAIGN_GREETINGS_HTML,
      CAMPAIGN_ID: location.state.campaign_id,
    });

    await fetch(AXIOS.defaultPort + AXIOS.updateCampaign, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CAMPAIGN_GREETING: tempCampaignPersonalizationArr,
        CAMPAIGN_GREETING_HTML: campaignGreetingData.CAMPAIGN_GREETINGS_HTML,
        CAMPAIGN_ID: location.state.campaign_id,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        toast.success("Campaign Personalization Updated");
        setTransactionFlags((prev) => ({
          ...prev,
          PERSONALIZATION_SAVE_LOADING: false,
        }));
        getCampaignData();
      })
      .catch((err) => {
        setTransactionFlags((prev) => ({
          ...prev,
          PERSONALIZATION_SAVE_LOADING: true,
        }));
      });
  };
  const getRecipientData = async () => {
    await fetch(AXIOS.defaultPort + AXIOS.emailRecipientGet, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ CAMPAIGN_ID: location.state.campaign_id }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.length > 0) {
          setRecipientStep("RECIPIENT_TABLE");
          setRecipientData(data);
        }
      })
      .catch((err) => {});
  };
  const getCampaignData = async () => {
    await fetch(AXIOS.defaultPort + AXIOS.getCampaignById, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ CAMPAIGN_ID: location.state.campaign_id }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.length > 0) {
          console.log("Asdnasdnkasd", data);

          setCampaignData(data[0]);
          setCampaignName({
            NAME: data[0].CAMPAIGN_NAME,
            DEFAULT_NAME: data[0].CAMPAIGN_NAME,
          });
          let tempShowCampaignDetails = {};
          if (data[0].CAMPAIGN_STATUS == "1") {
            tempShowCampaignDetails = {
              STATUS: false,
              STATUS_CODE: data[0].CAMPAIGN_STATUS,
            };
          } else if (
            data[0].CAMPAIGN_STATUS == "2" ||
            data[0].CAMPAIGN_STATUS == "3"
          ) {
            tempShowCampaignDetails = {
              STATUS: true,
              STATUS_CODE: data[0].CAMPAIGN_STATUS,
            };
          }

          setShowCampaignDetailsPage(tempShowCampaignDetails);

          let TempDroppedItem = [];

          if (
            data[0]?.CAMPAIGN_GREETING &&
            data[0].CAMPAIGN_GREETING.length > 0
          ) {
            console.log("ASdjlasndjkasda", data[0].CAMPAIGN_GREETING);

            data[0].CAMPAIGN_GREETING.map((val) => {
              let tempDroppableItem = DroppableItems.find(
                (item) => item.id == val
              );
              console.log(
                "ASdkjasndjkasdasd",
                tempDroppableItem,
                val,
                DroppableItems
              );
              TempDroppedItem.push({ ...tempDroppableItem });
            });
          }
          setDroppedItems(TempDroppedItem);
        }
      })
      .catch((err) => {});
  };

  const uploadRecipientFile = async () => {
    if (recipientFile != "") {
      let recipientFormData = new FormData();
      recipientFormData.append("emailFile", recipientFile);
      recipientFormData.append("campaignId", location.state.campaign_id);
      await fetch(AXIOS.defaultPort + AXIOS.recipientContactImport, {
        method: "POST",
        body: recipientFormData,
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          if (data.NAME_ERROR == true) {
            toast.error("Contact Name not found in the sheet");
          }
          if (data.EMAIL_ERROR == true) {
            toast.error("Contact Email not found in the sheet");
          }
          if (data.SUCCESS == true) {
            toast.success("Import Successfully");
          }

          getRecipientData();
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          //  setUploadStatus("An error occurred during upload.");
        });
    } else {
      toast.error("Please upload the file");
    }
  };

  useEffect(() => {
    let tempMainScreenFields = [...mainScreenFields];
    let MailToIndex = tempMainScreenFields.findIndex((val) => val.id == 1);
    if (MailToIndex !== -1) {
      if (recipientData && recipientData.length > 0) {
        tempMainScreenFields[MailToIndex].value =
          `${recipientData.length} Recipient`;
        tempMainScreenFields[MailToIndex].status = "complete";
      } else {
        tempMainScreenFields[MailToIndex].value = "";
        tempMainScreenFields[MailToIndex].status = "error";
      }
    }
    setMainScreenFields(tempMainScreenFields);
  }, [recipientData]);
  useEffect(() => {
    let tempMainScreenFields = [...mainScreenFields];
    let PersonalizationIndex = tempMainScreenFields.findIndex(
      (val) => val.id == 6
    );
    if (PersonalizationIndex !== -1) {
      if (
        campaignData?.CAMPAIGN_GREETING &&
        campaignData?.CAMPAIGN_GREETING.length > 0
      ) {
        console.log("Asdjlnasdjsadas", campaignData.CAMPAIGN_GREETING);
        
        tempMainScreenFields[PersonalizationIndex].value =
          campaignData.CAMPAIGN_GREETING.map((val) => val).join(" ");
        tempMainScreenFields[PersonalizationIndex].status = "complete";
      } else {
        tempMainScreenFields[PersonalizationIndex].value = "";
        tempMainScreenFields[PersonalizationIndex].status = "error";
      }
    }
    setMainScreenFields(tempMainScreenFields);
  }, [campaignData]);
  useEffect(() => {
    let tempMainScreenFields = [...mainScreenFields];
    let contentIndex = tempMainScreenFields.findIndex((val) => val.id == 5);
    if (contentIndex !== -1) {
      if (emailContentData?.TEMPLATE_HTML != null) {
        tempMainScreenFields[contentIndex].status = "complete";
      } else {
        tempMainScreenFields[contentIndex].status = "error";
      }
    }
    setMainScreenFields(tempMainScreenFields);
  }, [emailContentData]);

  useEffect(() => {
    let tempMainScreenFields = [...mainScreenFields];
    let subjectIndex = tempMainScreenFields.findIndex((val) => val.id == 3);
    let FromIndex = tempMainScreenFields.findIndex((val) => val.id == 2);

    if (subjectIndex !== -1) {
      if (
        campaignSubjectData.SUBJECT_SET == true &&
        campaignSubjectData.SUBJECT == ""
      ) {
        tempMainScreenFields[subjectIndex].status = "error";
      } else {
        tempMainScreenFields[subjectIndex].status = "complete";
      }
      tempMainScreenFields[subjectIndex].value =
        campaignSubjectData.SUBJECT || "";
      tempMainScreenFields[subjectIndex].PREVIEW_TEXT =
        campaignSubjectData.PREVIEW_TEXT || "";
    }

    if (FromIndex != -1) {
      if (campaignSubjectData.SUBJECT_SET == true) {
        if (campaignSubjectData.FROM_NAME_DEFAULT != "") {
          tempMainScreenFields[FromIndex].value =
            campaignSubjectData.FROM_NAME_DEFAULT || "";
          tempMainScreenFields[FromIndex].status = "complete";
        } else {
          tempMainScreenFields[FromIndex].value = "";
          tempMainScreenFields[FromIndex].status = "error";
        }
        if (campaignSubjectData.FROM_EMAIL_DEFAULT != "") {
          tempMainScreenFields[FromIndex].description =
            campaignSubjectData.FROM_EMAIL_DEFAULT || "";
        } else {
        }
      }
    }
    setMainScreenFields(tempMainScreenFields);
  }, [campaignSubjectData]);

  const [mainScreenFields, setMainScreenFields] = useState([
    {
      id: 1,
      label: "To: ",
      description: "Who are you sending this email to?",
      buttonLabel: "Add recipients",
      value: `${recipientData ? recipientData.length : ""} Recipient`,
      status: "complete",
      onClick: () => {
        setRecipientsDrawerState(true);
      },
    },
    {
      id: 2,
      label: "From: ",
      description: "",
      buttonLabel: "Edit from",
      status: "complete",
      onClick: () => {
        setFromDrawerState(true);
      },
    },
    {
      id: 3,
      label: "Subject: ",
      description: "What's the subject line for this email?",
      buttonLabel: "Add subject",
      value: "",
      PREVIEW_TEXT: "",
      status: "error",
      onClick: () => {
        setSubjectDrawerState(true);
      },
    },
    {
      id: 6,
      label: "Personalization: ",
      description: "How would you Address the recipient by name in this email?",
      buttonLabel: "Add",
      value: "",
      PREVIEW_TEXT: "",
      status: "error",
      onClick: () => {
        setPersonalizationDrawerState(true);
      },
    },
    {
      id: 4,
      label: "Send time",
      description: "Send now",
      buttonLabel: "Edit send time",
      status: "complete",
      onClick: () => {
        toast.success("Feature coming soon...");
        // setSubjectDrawerState(true);
      },
    },
    {
      id: 5,
      label: "Content",
      description: "Design the Content for your email",
      buttonLabel: "Design Mail",
      status: "complete",
      onClick: () => {},
    },
    {
      id: 5,
      label: "Attachments",
      description: "Attach the file for your email",
      buttonLabel: "Attach File",
      status: "complete",
      onClick: () => {
        toast.success("Feature coming soon...");
      },
    },
  ]);

  useEffect(() => {
    let tempMainScreenFields = [...mainScreenFields];
    const contentIndex = tempMainScreenFields.findIndex((val) => val.id == 5);

    if (contentIndex != -1) {
      if (emailContentData?.TEMPLATE_IMAGE) {
        tempMainScreenFields[contentIndex].buttonLabel = "Edit Mail";
        tempMainScreenFields[contentIndex].onClick = () => {
          navigate("/email-template-edit", {
            state: {
              campaign_id: location.state.campaign_id,
              DESIGN: emailContentData.DESIGN,
              SAVE_DESIGN: false,
            },
          });
        };
      } else {
        tempMainScreenFields[contentIndex].buttonLabel = "Design Mail";
        tempMainScreenFields[contentIndex].onClick = () => {
          navigate("/email-templates", {
            state: {
              campaign_id: location.state.campaign_id,
              SAVE_DESIGN: false,
            },
          });
        };
      }
    }
  }, [emailContentData]);
  const [showSubjectPicker, setShowSubjectPicker] = useState(false);
  const [showPreviewTxtPicker, setShowPreviewTxtPicker] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const SubjectPickerRef = useRef(null);
  const PreviewTxtPickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        SubjectPickerRef.current &&
        !SubjectPickerRef.current.contains(event.target)
      ) {
        setShowSubjectPicker(false);
      }
      if (
        PreviewTxtPickerRef.current &&
        !PreviewTxtPickerRef.current.contains(event.target)
      ) {
        setShowPreviewTxtPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [CampaignErrors, setCampaignErrors] = useState({
    ERROR_FOUND: false,
    TRANSACTION_STARTED: false,
  });

  const sendCampaignEmails = async () => {
    setCampaignErrors((prev) => ({
      ...prev,
      TRANSACTION_STARTED: true,
    }));
    await fetch(AXIOS.defaultPort + AXIOS.emailSend, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CAMPAIGN_ID: location.state.campaign_id,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        let errorFound = false;
        if (data.RECIPIENTS_ERROR) {
          errorFound = true;
          toast.error("Please add recipients");
        }

        if (data.TEMPLATE_SUBJECT_ERROR) {
          errorFound = true;
          toast.error("Please save Subject");
        }
        if (data.TEMPLATE_HTML_ERROR) {
          errorFound = true;
          toast.error("Please save email content");
        }
        if (data.TEMPLATE_DESIGN_ERROR) {
          errorFound = true;
          toast.error(
            "Something went wrong, Please try saving Email content again"
          );
        }
        if (errorFound == false) {
          // toast.success("Campaign Started Successfully");
          setShowConfetti(true);

          // getCampaignData();

          getEmailContent();
          setCampaignErrors({
            ...data,
            ERROR_FOUND: errorFound,
            TRANSACTION_STARTED: false,
          });
        } else {
          setCampaignErrors({
            ...data,
            ERROR_FOUND: errorFound,
            TRANSACTION_STARTED: false,
          });
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (showConfetti == true) {
      setTimeout(() => {
        setShowConfetti(false);
        setShowCampaignDetailsPage((prev) => ({
          ...prev,
          STATUS: true,
          STATUS_CODE: "2",
        }));
      }, 3000);
    }
  }, [showConfetti]);
  const openURLInBrowser = (url) => {
    window.open(url, "_blank"); // Opens the URL in a new tab
  };
  const [url, setUrl] = useState("");
  const [previewUrl, setPreviewUrl] = useState({
    HTML_PREVIEW: "",
    HTML_TEXT: "",
  });

  const handlePreview = (url) => {
    if (url) {
      try {
        const isValidUrl = new URL(url); // Validate URL format
        setPreviewUrl({
          HTML_PREVIEW: url,
        }); // Set the validated URL for preview
        fetch(url)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.text(); // Get response as plain text
          })
          .then((text) => {
            setPreviewUrl((prev) => ({
              ...prev,
              HTML_TEXT: text,
            })); // Update the content state
          })
          .catch((err) => {
            console.error("Failed to fetch content:", err);
            // setError("Failed to load content. Please try again later.");
          });
      } catch (error) {
        alert("Invalid URL! Please enter a valid URL.");
      }
    }
  };

  const [campaignAnalysis, setCampaignAnalysis] = useState([
    {
      TITLE: "Delivered Emails",
      ID: "1",
      VALUE: "",
      SHOW: true,
      HOVER_SHADOW: "",
    },
    {
      TITLE: "Delivered Emails %",
      VALUE: "",
      ID: "2",

      SHOW: true,
      HOVER_SHADOW: "",
    },
    {
      TITLE: "Opened Emails",
      VALUE: "",
      ID: "3",
      SHOW: true,
      HOVER_SHADOW: "",
    },
    {
      TITLE: "Opened Emails %",
      VALUE: "",
      ID: "4",
      SHOW: true,
      HOVER_SHADOW: "",
    },
    {
      TITLE: "Recipients",
      VALUE: "",
      ID: "5",
      SHOW: true,
      HOVER_SHADOW: "",
    },
    // {
    //   TITLE: "Sent Email",
    //   VALUE: "",
    //   SHOW: true,
    //   HOVER_SHADOW: "",
    // },
    {
      TITLE: "Pending Email",
      VALUE: "",
      ID: "6",
      SHOW: true,
      HOVER_SHADOW: "",
    },
    {
      TITLE: "Bounced Emails",
      VALUE: "",
      ID: "7",
      SHOW: true,
      HOVER_SHADOW: "",
    },
    {
      TITLE: "Bounced Emails %",
      VALUE: "",
      ID: "8",
      SHOW: true,
      HOVER_SHADOW: "",
    },
  ]);
  const [copied, setCopied] = useState(false);
  const handleCopy = (content) => {
    navigator.clipboard.writeText(content).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    });
  };

  const sendTestEmail = () => {
    if (sendTestEmailQuery.EMAILS != "") {
      setTransactionFlags((prev) => ({
        ...prev,
        SEND_TEST_EMAIL_LOADING: true,
      }));
      const tempSendTestEmailQuery = sendTestEmailQuery.EMAILS.split(",").map(
        (email) => email.trim()
      );

      fetch(AXIOS.defaultPort + AXIOS.testEmailSend, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          CAMPAIGN_ID: location.state.campaign_id,
          RECIPIENTS: tempSendTestEmailQuery,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setSendTestEmailQuery((prev) => ({
            ...prev,
            EMAIL_SENT: true,
          }));
          setTransactionFlags((prev) => ({
            ...prev,
            SEND_TEST_EMAIL_LOADING: false,
          }));
        })
        .catch((err) => {
          setTransactionFlags((prev) => ({
            ...prev,
            SEND_TEST_EMAIL_LOADING: true,
          }));
        });
    } else {
      toast.error("Please enter Email ID");
      setSendTestEmailQuery((prev) => ({
        ...prev,
        EMAIL_ERROR: true,
      }));
    }
  };
  return (
    <MainScreen>
      <Toaster />

      {showConfetti && (
        <div className="fullscreen-loader">
          <Lottie
            style={{
              width: 450,
              height: 450,
            }}
            play
            animationData={confettiAnime}
          />
          <p>Campaign is live !</p>
        </div>
      )}

      {showCampaignDetailsPage?.STATUS == false && (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: 2,
              // pb: 1,
              mt: 3,
            }}
          >
            <Box
              sx={{
                mr: 1,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                navigate("/view-campaign");
              }}
            >
              <IconContext.Provider value={{ size: 25, color: "#000" }}>
                <FaArrowLeftLong />
              </IconContext.Provider>
            </Box>
            <Typography
              style={{
                fontSize: 22,
                fontWeight: 600,
                color: "#000",
                marginRight: 10,
                textAlign: "start",
              }}
            >
              Campaign Details
            </Typography>
          </Box>
          <Box
            className="campaign-details-container"
            sx={{
              mt: 3,
              p: 1,
            }}
          >
            <div className="container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {emailContentData?.TEMPLATE_HTML && (
                  <button
                    className="prev-email-template"
                    style={{
                      marginRight: 10,
                    }}
                    onClick={() => {
                      setTestEmailDrawerState(true);
                    }}
                  >
                    Send Sample
                    <img
                      src={mailBoxIcon}
                      style={{
                        width: 30,
                        height: 30,
                      }}
                    />
                  </button>
                )}

                {emailContentData?.TEMPLATE_HTML && (
                  <button
                    className="prev-email-template"
                    style={{
                      marginRight: 10,
                    }}
                    onClick={() => {
                      openURLInBrowser(
                        AXIOS.defaultPort + emailContentData.TEMPLATE_HTML
                      );
                    }}
                  >
                    Email Preview
                    <img
                      src={previewEmailIcon}
                      style={{
                        width: 30,
                        height: 30,
                      }}
                    />
                  </button>
                )}

                <div className="email-button-container">
                  <label
                    className="email-label"
                    onClick={() => {
                      setCampaignSendDrawerState(true);
                    }}
                  >
                    <span className="email-circle">
                      <svg
                        className="email-icon"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M12 19V5m0 14-4-4m4 4 4-4"
                        />
                      </svg>
                      <div className="email-square" />
                    </span>
                    <p className="email-title">Send Email</p>
                  </label>
                </div>
              </div>
              {campaignName.EDIT_FLAG ? (
                <div
                  style={{
                    marginTop: 10,
                  }}
                >
                  <CustomInput
                    type="text"
                    Value={campaignName.NAME}
                    Style={{
                      height: 40,
                      fontSize: 20,
                    }}
                    onChange={(e) => {
                      setCampaignName({
                        ...campaignName,
                        NAME: e.target.value,
                      });
                    }}
                  />

                  <div
                    className="footer-buttons"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "20px",
                    }}
                  >
                    <button
                      className="filled-button"
                      onClick={() => {
                        campaignNameUpdate();
                      }}
                    >
                      Save
                    </button>
                    <button
                      className="bordered-button"
                      onClick={() => {
                        setCampaignName({
                          ...campaignName,
                          EDIT_FLAG: false,
                          NAME: campaignName.DEFAULT_NAME,
                        });
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <span className="edit-name-title">{campaignName.NAME}</span>
                  <br />
                  <span
                    className="edit-name"
                    onClick={() => {
                      setCampaignName({ ...campaignName, EDIT_FLAG: true });
                    }}
                  >
                    Edit name
                  </span>
                </div>
              )}

              {mainScreenFields.map((item) => (
                <div key={item.id} className={`email-item ${item.status}`}>
                  <div className="email-item-label">
                    <span
                      className={`status-icon ${item.status === "error" ? "error" : ""}`}
                      style={{
                        color: "#fff",
                      }}
                    >
                      {item.status === "complete"
                        ? "✔"
                        : // : item.status === "error"
                          //   ? "⛔"
                          "✖"}
                    </span>
                    <div>
                      <span>
                        {item.label} {item.value}
                        {item?.PREVIEW_TEXT && (
                          <>
                            <br />{" "}
                            <span
                              className="email-item-description"
                              style={{
                                fontSize: 15,
                              }}
                            >
                              Preview Text: {item?.PREVIEW_TEXT}
                            </span>
                          </>
                        )}
                      </span>
                      <p className="email-item-description">
                        {item.description}
                      </p>
                    </div>
                  </div>
                  <button onClick={item.onClick}>{item.buttonLabel}</button>
                </div>
              ))}
            </div>
            <div className="right-container">
              <Box className="right-sub-container">
                <div style={{ textAlign: "center", margin: "20px" }}>
                  {previewUrl?.HTML_PREVIEW && (
                    <div
                      style={{
                        marginTop: "20px",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        overflow: "hidden",
                      }}
                    >
                      <iframe
                        src={previewUrl.HTML_PREVIEW}
                        title="Link Preview"
                        style={{
                          width: "100%",
                          height: "500px",
                          border: "none",
                        }}
                      />
                    </div>
                  )}
                </div>
              </Box>
            </div>
          </Box>

          <SwipeableDrawer
            anchor={"bottom"}
            PaperProps={{
              sx: {
                borderRadius: "16px 16px 0 0",
                margin: "0 10px 0 10px",
                marginTop: "10px",
                overflow: "hidden",
              },
            }}
            open={recipientsDrawerState}
            onClose={() => {
              setRecipientsDrawerState(false);
            }}
            onOpen={() => {
              setRecipientsDrawerState(true);
            }}
          >
            <Box
              sx={{
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontSize: 25,
                    color: "#000",
                    textAlign: "start",
                    fontWeight: "bold",
                    fontFamily: "poppin",
                  }}
                >
                  Import Recipients
                </Typography>
                <CancelIcon
                  sx={{
                    width: 40,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setRecipientsDrawerState(false);
                  }}
                />
              </Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  // sx={{
                  //   width: "80%",
                  // }}
                  className="import-recipient-wrapper"
                >
                  {recipientStep != "RECIPIENT_TABLE" && (
                    <p
                      className="text-means-web recipient-title"
                      style={{
                        textAlign: "start",
                      }}
                    >
                      How would you prefer to add contacts?
                    </p>
                  )}
                  <p className="text-means-web recipient-description">
                    Your contacts are your audience—the people you'll email. In
                    this step, we'll guide you through importing your data and
                    setting you up for success.
                  </p>

                  {recipientData &&
                    recipientData.length > 0 &&
                    recipientStep == "" && (
                      <button
                        className="back-button"
                        style={{
                          height: 40,
                          marginTop: 5,
                        }}
                        onClick={() => {
                          setRecipientStep("RECIPIENT_TABLE");
                        }}
                      >
                        <span className="back-text"> Show Recipient List</span>
                        <img
                          src={rightArrow}
                          alt="Back"
                          className="back-icon"
                        />
                      </button>
                    )}
                  {recipientStep == "" && (
                    <div className="import-card-main-container">
                      {recipientImportOptions.map((val) => {
                        return (
                          <div
                            className="import-card-container"
                            onClick={val.onClick}
                          >
                            <div
                              className="image-wrapper"
                              style={{
                                marginLeft: val.MARGIN,
                              }}
                            >
                              <img
                                src={val.IMAGE}
                                alt="Contact Import"
                                className="card-image"
                              />
                            </div>
                            <div className="card-content">
                              <h3>{val.TITLE}</h3>
                              {val.DeSCRIPTION}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {recipientStep == "UPLOAD_FILE" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <button
                          className="back-button"
                          onClick={() => {
                            setRecipientStep("");
                            setRecipientFile("");
                          }}
                        >
                          <img
                            src={leftArrow}
                            alt="Back"
                            className="back-icon"
                          />
                          {/* <span className="back-text">Back</span> */}
                        </button>
                        <Typography
                          style={{
                            fontSize: 25,
                            color: "#000",
                            textAlign: "start",
                            fontWeight: "bold",
                            fontFamily: "poppin",
                            marginLeft: 10,
                          }}
                        >
                          Upload File
                        </Typography>
                      </div>
                      <div className="import-csv-main-container">
                        <div className="import-csv-container">
                          <input
                            type="file"
                            id="csvInput"
                            accept=".csv,.xlsx"
                            onChange={handleFileUpload}
                            style={{ display: "none" }}
                          />
                          <label htmlFor="csvInput" className="upload-label">
                            <div className="upload-box">
                              <img
                                src="https://cdn-icons-png.flaticon.com/512/158/158749.png" // Replace with your preferred upload icon
                                alt="Upload"
                                className="upload-icon"
                              />
                              <p className="upload-text">
                                {recipientFile?.name ||
                                  "Click to upload a .csv file"}
                              </p>
                            </div>
                          </label>
                        </div>
                        <a
                          className="csv-template-download"
                          href="https://pdhanewala.com:9002/recipientExcelTemplate.xlsx"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="70"
                            height="70"
                            fill="currentColor"
                          >
                            <path d="M5 20h14a1 1 0 0 0 1-1v-2h-2v1H6v-1H4v2a1 1 0 0 0 1 1zM11 4v8.586L8.707 10.293l-1.414 1.414L12 16.414l4.707-4.707-1.414-1.414L13 12.586V4h-2z" />
                          </svg>
                          <h3>Download</h3>
                          <span>
                            CSV template for a well-structured and formatted
                            reference.
                          </span>
                        </a>
                      </div>

                      <div
                        className="footer-buttons"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "20px",
                        }}
                      >
                        <button
                          className="filled-button"
                          onClick={() => {
                            uploadRecipientFile();
                          }}
                        >
                          Save
                        </button>
                        <button
                          className="bordered-button"
                          onClick={() => {
                            setRecipientStep("");
                            setRecipientFile("");
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  )}

                  {recipientStep == "RECIPIENT_TABLE" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <button
                          className="back-button"
                          style={{
                            height: 40,
                            marginTop: 5,
                          }}
                          onClick={() => {
                            setRecipientStep("");
                            setRecipientFile("");
                          }}
                        >
                          <img
                            src={leftArrow}
                            alt="Back"
                            className="back-icon"
                          />
                          <span className="back-text">Show Upload options</span>
                        </button>
                      </div>
                      <Box sx={{}}>
                        <ReactDataTable
                          columns={recipientTableColumns}
                          data={recipientData}
                          pageCount={10}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </SwipeableDrawer>
          {/* Subject Modal */}
          <SwipeableDrawer
            anchor={"bottom"}
            PaperProps={{
              sx: {
                borderRadius: "16px 16px 0 0", // Rounded top-left and top-right corners only
                margin: "0 10px 0 10px", // Margin applied to left and right edges
                marginTop: "10px", // Margin applied to the top edge
                overflow: "hidden", // Preserve rounded corners
              },
            }}
            open={SubjectDrawerState}
            onClose={() => {
              setSubjectDrawerState(false);
            }}
            onOpen={() => {
              setSubjectDrawerState(true);
            }}
          >
            <Box
              sx={{
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontSize: 25,
                    color: "#000",
                    textAlign: "start",
                    fontWeight: "bold",
                    fontFamily: "poppin",
                  }}
                >
                  Add Subject
                </Typography>
                <CancelIcon
                  sx={{
                    width: 40,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSubjectDrawerState(false);
                  }}
                />
              </Box>
              <Divider />
              <Typography
                sx={{
                  fontSize: 20,
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "start",
                  fontFamily: "poppins",
                }}
              >
                What's the subject line for this email?
              </Typography>
              <Typography
                sx={{
                  fontSize: 17,
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "start",
                  mt: 2,
                  fontFamily: "poppins",
                }}
              >
                Subject
                <span
                  style={{
                    color: "red",
                  }}
                >
                  *
                </span>
              </Typography>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <CustomInput
                  Placeholder={"Subject"}
                  Style={{
                    height: 40,
                  }}
                  Value={campaignSubjectData.SUBJECT}
                  onChange={(e) => {
                    setCampaignSubjectData((prev) => ({
                      ...prev,
                      SUBJECT: e.target.value,
                    }));
                  }}
                />
                <div style={{ position: "relative" }}>
                  <button
                    onClick={() => setShowSubjectPicker((prev) => !prev)}
                    style={{
                      borderRadius: 10,
                      height: 40,
                      width: 40,
                      border: "1px solid gray",
                      background: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    {/* {selectedEmoji || "😊"} */}
                    <img
                      src={EmojiAdd}
                      style={{
                        height: 25,
                        width: 25,
                      }}
                    />
                  </button>

                  {/* Emoji Picker */}
                  {showSubjectPicker && (
                    <div
                      ref={SubjectPickerRef}
                      style={{
                        position: "absolute",
                        top: "100%",
                        right: "0",
                        marginTop: "5px",
                        zIndex: 1000,
                      }}
                    >
                      <EmojiPicker
                        onEmojiClick={(emojiData) => {
                          // setSelectedEmoji(emojiData.emoji);
                          let tempCampaignSubjectData = {
                            ...campaignSubjectData,
                          };
                          tempCampaignSubjectData.SUBJECT += emojiData.emoji;
                          setCampaignSubjectData(tempCampaignSubjectData);
                          // setShowSubjectPicker(false); // Close the picker after selection
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <Typography
                sx={{
                  fontSize: 17,
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "start",
                  mt: 2,
                  fontFamily: "poppins",
                }}
              >
                Preview Text
                <span
                  style={{
                    color: "red",
                  }}
                >
                  *
                </span>
              </Typography>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <CustomInput
                  Placeholder={"Preview Text"}
                  Style={{
                    height: 40,
                  }}
                  Value={campaignSubjectData.PREVIEW_TEXT}
                  onChange={(e) => {
                    setCampaignSubjectData((prev) => ({
                      ...prev,
                      PREVIEW_TEXT: e.target.value,
                    }));
                  }}
                />

                <div style={{ position: "relative" }}>
                  <button
                    onClick={() => setShowPreviewTxtPicker((prev) => !prev)}
                    style={{
                      // padding: 5,
                      borderRadius: 10,
                      height: 40,
                      width: 40,
                      border: "1px solid gray",
                      background: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    {/* {selectedEmoji || "😊"} */}
                    <img
                      src={EmojiAdd}
                      style={{
                        height: 25,
                        width: 25,
                      }}
                    />
                  </button>

                  {/* Emoji Picker */}
                  {showPreviewTxtPicker && (
                    <div
                      ref={PreviewTxtPickerRef}
                      style={{
                        position: "absolute",
                        top: "100%",
                        right: "0",
                        marginTop: "5px",
                        zIndex: 1000,
                      }}
                    >
                      <EmojiPicker
                        onEmojiClick={(emojiData) => {
                          // setSelectedEmoji(emojiData.emoji);
                          let tempCampaignSubjectData = {
                            ...campaignSubjectData,
                          };
                          tempCampaignSubjectData.PREVIEW_TEXT +=
                            emojiData.emoji;
                          setCampaignSubjectData(tempCampaignSubjectData);
                          // setShowPreviewTxtPicker(false); // Close the picker after selection
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <span className="text-means-web">
                Preview text appears in the inbox after the subject line.
              </span>

              <div
                className="footer-buttons"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                  marginTop: 15,
                }}
              >
                <button
                  className="bordered-button"
                  onClick={() => {
                    setSubjectDrawerState(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="filled-button"
                  onClick={() => {
                    campaignSubjectUpdate("SUBJECT");
                  }}
                  style={{
                    minWidth: 100,
                  }}
                  disabled={transactionFlags.SUBJECT_SAVE_LOADING}
                >
                  {transactionFlags.SUBJECT_SAVE_LOADING == true ? (
                    <Spinner />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
              <div className="effective-subject-card-container">
                <h2 className="card-title">Craft the Perfect Subject Line</h2>
                <ul
                  className="card-content"
                  style={{
                    textAlign: "start",
                  }}
                >
                  <li>
                    <strong>Keep it concise:</strong> Subject lines with fewer
                    than 9 words tend to perform better.
                  </li>
                  <li>
                    <strong>Use emojis wisely:</strong> A single emoji can
                    enhance engagement—use no more than one.
                  </li>
                  <li>
                    <strong>Stay within the limit:</strong> Keep your subject
                    line under 60 characters for clarity.
                  </li>
                  <li>
                    <strong>Avoid clutter:</strong> Minimize punctuation to keep
                    your message clean and clear.
                  </li>
                </ul>
                <p className="card-footer">
                  Your subject line is a perfect mix of creativity and clarity!
                </p>
              </div>
            </Box>
          </SwipeableDrawer>

          {/* Send test Email  */}

          <SwipeableDrawer
            anchor={"bottom"}
            PaperProps={{
              sx: {
                borderRadius: "16px 16px 0 0", // Rounded top-left and top-right corners only
                margin: "0 10px 0 10px", // Margin applied to left and right edges
                marginTop: "10px", // Margin applied to the top edge
                overflow: "hidden", // Preserve rounded corners
              },
            }}
            open={TestEmailDrawerState}
            onClose={() => {
              setTestEmailDrawerState(false);
            }}
            onOpen={() => {
              setTestEmailDrawerState(true);
            }}
          >
            <Box
              sx={{
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontSize: 25,
                    color: "#000",
                    textAlign: "start",
                    fontWeight: "bold",
                    fontFamily: "poppin",
                  }}
                >
                  Send Sample Email
                </Typography>
                <CancelIcon
                  sx={{
                    width: 40,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setTestEmailDrawerState(false);
                  }}
                />
              </Box>
              <Divider />

              {sendTestEmailQuery.EMAIL_SENT == true && (
                <>
                  <Typography
                    sx={{
                      fontSize: 19,
                      color: "#000",
                      textAlign: "start",
                      fontFamily: "poppins",
                      mt: 4,
                      mb: 4,
                    }}
                  >
                    Your test email should be delivered to the marked recipients
                    . If the recipients do not receive the email, please try
                    resending it.
                  </Typography>
                  <div
                    className="footer-buttons"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "20px",
                      marginTop: 15,
                    }}
                  >
                    <button
                      className="bordered-button"
                      onClick={() => {
                        setSendTestEmailQuery({
                          EMAIL_ERROR: false,
                          EMAILS: "",
                          EMAIL_SENT: false,
                        });
                      }}
                    >
                      Send Another Sample Email
                    </button>
                    <button
                      className="filled-button"
                      onClick={() => {
                        setTestEmailDrawerState(false);
                        setSendTestEmailQuery({
                          EMAIL_ERROR: false,
                          EMAILS: "",
                          EMAIL_SENT: false,
                        });
                      }}
                    >
                      Okay
                    </button>
                  </div>
                </>
              )}

              {sendTestEmailQuery.EMAIL_SENT == false && (
                <Box>
                  <Typography
                    sx={{
                      fontSize: 17,
                      color: "#000",
                      fontWeight: "bold",
                      textAlign: "start",
                      mt: 2,
                      fontFamily: "poppins",
                    }}
                  >
                    Send Sample Email to
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CustomInput
                      Placeholder={
                        "Ex: john@betacodeinfotech.com, joy@betacodeinfotech.com..."
                      }
                      Style={{
                        height: 40,
                      }}
                      Value={sendTestEmailQuery.EMAILS}
                      onChange={(e) => {
                        setSendTestEmailQuery((prev) => ({
                          ...prev,
                          EMAILS: e.target.value,
                          EMAIL_ERROR: false,
                        }));
                      }}
                    />
                  </div>
                  {sendTestEmailQuery.EMAIL_ERROR && (
                    <Typography style={{ fontSize: 14, color: "#d32f2f" }}>
                      Please enter Email ID
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      fontSize: 17,
                      color: "gray",
                      textAlign: "start",
                      fontFamily: "poppins",
                    }}
                  >
                    Use commas to separate multiple emails
                  </Typography>

                  <div
                    className="footer-buttons"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "20px",
                      marginTop: 15,
                    }}
                  >
                    <button
                      className="bordered-button"
                      onClick={() => {
                        setTestEmailDrawerState(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="filled-button"
                      onClick={() => {
                        sendTestEmail();
                      }}
                      style={{
                        minWidth: 100,
                      }}
                      disabled={transactionFlags.SEND_TEST_EMAIL_LOADING}
                    >
                      {transactionFlags.SEND_TEST_EMAIL_LOADING == true ? (
                        <Spinner />
                      ) : (
                        "Send"
                      )}
                    </button>
                  </div>
                </Box>
              )}
            </Box>
          </SwipeableDrawer>

          <SwipeableDrawer
            anchor={"bottom"}
            PaperProps={{
              sx: {
                borderRadius: "16px 16px 0 0", // Rounded top-left and top-right corners only
                margin: "0 10px 0 10px", // Margin applied to left and right edges
                marginTop: "10px", // Margin applied to the top edge
                overflow: "hidden", // Preserve rounded corners
              },
            }}
            open={CampaignSendDrawerState}
            onClose={() => {
              setCampaignSendDrawerState(false);
            }}
            onOpen={() => {
              setCampaignSendDrawerState(true);
            }}
          >
            <Box
              sx={{
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontSize: 25,
                    color: "#000",
                    textAlign: "start",
                    fontWeight: "bold",
                    fontFamily: "poppin",
                  }}
                >
                  Confirmation
                </Typography>
                <CancelIcon
                  sx={{
                    width: 40,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCampaignSendDrawerState(false);
                  }}
                />
              </Box>
              <Divider />
              <p className="text-means-web recipient-description">
                Are you sure you want to launch the campaign? Please review and
                confirm the recipient details, subject, and email content before
                proceeding with sending.
              </p>
              <div className="campaign-review-container">
                <div className="campaign-detail">
                  <strong className="text-means-web recipient-description">
                    Subject:
                  </strong>
                  <p className=" text-means-web recipient-description">
                    {emailContentData?.SUBJECT} {emailContentData?.PREVIEW_TEXT}
                  </p>
                </div>
                <div className="campaign-detail">
                  <strong className="text-means-web recipient-description">
                    To:
                  </strong>
                  <p className=" text-means-web recipient-description">
                    {recipientData && `${recipientData.length} Recipient`}
                  </p>
                </div>
                <div className="campaign-detail">
                  <strong className="text-means-web recipient-description">
                    Attachments:
                  </strong>
                  <p className=" text-means-web recipient-description"></p>
                </div>
                <div className="campaign-detail">
                  <strong className="text-means-web recipient-description">
                    Email Content:
                  </strong>
                  <div>
                    {emailContentData?.TEMPLATE_HTML && (
                      <button
                        className="prev-email-template"
                        style={{
                          marginRight: 10,
                          height: 50,
                          width: 200,
                        }}
                        onClick={() => {
                          openURLInBrowser(
                            AXIOS.defaultPort + emailContentData?.TEMPLATE_HTML
                          );
                        }}
                      >
                        View Email Preview
                        <img
                          src={previewEmailIcon}
                          style={{
                            width: 30,
                            height: 30,
                          }}
                        />
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="footer-buttons"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                }}
              >
                <button
                  className="filled-button"
                  onClick={() => {
                    sendCampaignEmails();
                  }}
                  style={{
                    minWidth: 200,
                  }}
                  disabled={CampaignErrors.TRANSACTION_STARTED}
                >
                  {CampaignErrors.TRANSACTION_STARTED == true ? (
                    <Spinner />
                  ) : (
                    "Launch Campaign"
                  )}
                </button>
                <button
                  className="bordered-button"
                  onClick={() => {
                    setCampaignSendDrawerState(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </Box>
          </SwipeableDrawer>

          <SwipeableDrawer
            anchor={"bottom"}
            PaperProps={{
              sx: {
                borderRadius: "16px 16px 0 0", // Rounded top-left and top-right corners only
                margin: "0 10px 0 10px", // Margin applied to left and right edges
                marginTop: "10px", // Margin applied to the top edge
                overflow: "hidden", // Preserve rounded corners
              },
            }}
            open={FromDrawerState}
            onClose={() => {
              setFromDrawerState(false);
            }}
            onOpen={() => {
              setFromDrawerState(true);
            }}
          >
            <Box
              sx={{
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontSize: 25,
                    color: "#000",
                    textAlign: "start",
                    fontWeight: "bold",
                    fontFamily: "poppin",
                  }}
                >
                  From
                </Typography>
                <CancelIcon
                  sx={{
                    width: 40,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setFromDrawerState(false);
                  }}
                />
              </Box>
              <Divider />
              <Typography
                sx={{
                  fontSize: 17,
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "start",
                  mt: 2,
                  fontFamily: "poppins",
                }}
              >
                From Name
                {/* <span
                  style={{
                    color: "red",
                  }}
                >
                  *
                </span> */}
              </Typography>
              <CustomInput
                Placeholder={"From Name"}
                Style={{
                  height: 40,
                }}
                Value={campaignSubjectData.FROM_NAME}
                onChange={(e) => {
                  setCampaignSubjectData((prev) => ({
                    ...prev,
                    FROM_NAME: e.target.value,
                  }));
                }}
              />
              <Typography
                sx={{
                  fontSize: 17,
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "start",
                  mt: 2,
                  fontFamily: "poppins",
                }}
              >
                From Email ID
                {/* <span
                  style={{
                    color: "red",
                  }}
                >
                  *
                </span> */}
              </Typography>
              <CustomInput
                Placeholder={"From Email ID"}
                Style={{
                  height: 40,
                }}
                Disabled={true}
                Value={campaignSubjectData.FROM_EMAIL}
                onChange={(e) => {
                  setCampaignSubjectData((prev) => ({
                    ...prev,
                    FROM_EMAIL: e.target.value,
                  }));
                }}
              />
              <div
                className="footer-buttons"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                }}
              >
                <button
                  className="bordered-button"
                  onClick={() => {
                    setCampaignSendDrawerState(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="filled-button"
                  onClick={() => {
                    campaignSubjectUpdate("FROM_NAME");
                  }}
                  style={{
                    minWidth: 100,
                  }}
                  disabled={transactionFlags.SUBJECT_SAVE_LOADING}
                >
                  {transactionFlags.SUBJECT_SAVE_LOADING == true ? (
                    <Spinner />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </Box>
          </SwipeableDrawer>
          <SwipeableDrawer
            anchor={"bottom"}
            PaperProps={{
              sx: {
                borderRadius: "16px 16px 0 0", // Rounded top-left and top-right corners only
                margin: "0 10px 0 10px", // Margin applied to left and right edges
                marginTop: "10px", // Margin applied to the top edge
                overflow: "hidden", // Preserve rounded corners
              },
            }}
            open={PersonalizationDrawerState}
            onClose={() => {
              setPersonalizationDrawerState(false);
            }}
            onOpen={() => {
              setPersonalizationDrawerState(true);
            }}
          >
            <Box
              sx={{
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontSize: 25,
                    color: "#000",
                    textAlign: "start",
                    fontWeight: "bold",
                    fontFamily: "poppin",
                  }}
                >
                  Add Personalization
                </Typography>
                <CancelIcon
                  sx={{
                    width: 40,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setPersonalizationDrawerState(false);
                  }}
                />
              </Box>
              <Divider />
              <div className="row" style={{ gap: "10px", padding: "5px" }}>
                <div className="col-4">
                  <Typography
                    style={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      textAlign: "start",
                    }}
                  >
                    Custom Greetings
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <CustomInput
                      Placeholder={"Custom Greetings"}
                      // icon={Icons.campaign}
                      Value={customGreetings.CUSTOM_GREETING_NAME}
                      onChange={(e) => {
                        setCustomGreetings((prev) => ({
                          ...prev,
                          CUSTOM_GREETING_NAME: e.target.value,
                          CUSTOM_GREETING_NAME_ERROR: false,
                        }));
                      }}
                    />
                    {customGreetings.CUSTOM_GREETING_NAME_ERROR && (
                      <p className="helper-text">
                        Please enter Campaign Description
                      </p>
                    )}
                    <button
                      className="filled-button"
                      onClick={() => {
                        let tempItems = [...DroppableItems];
                        tempItems.push({
                          id: customGreetings.CUSTOM_GREETING_NAME,
                          content: customGreetings.CUSTOM_GREETING_NAME,
                        });
                        setDroppableItems(tempItems);
                        setCustomGreetings((prev) => ({
                          ...prev,
                          CUSTOM_GREETING_NAME: "",
                          CUSTOM_GREETING_NAME_ERROR: false,
                        }));
                      }}
                      style={{
                        minWidth: 100,
                        marginLeft: 10,
                        backgroundColor: "#007bff",
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>

              <DragDropContext onDragEnd={handleOnDragEnd}>
                <div style={{ display: "flex", gap: "10px", padding: "5px" }}>
                  <Droppable droppableId="draggable-list" isDropDisabled={true}>
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          padding: "16px",
                          background: "#e8e8e8",
                          borderRadius: "8px",
                          minHeight: "200px",
                          width: "600px",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "2px",
                        }}
                      >
                        <span style={{ width: "100%", fontWeight: "bold" }}>
                          Select from Greetings and Names
                        </span>

                        {DroppableItems.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  padding: "8px",
                                  background: "#ffffff",
                                  backgroundColor: "#fff",
                                  border: "1px solid #ccc",
                                  borderRadius: "4px",
                                  textAlign: "center",
                                  cursor: "grab",
                                  fontSize: 10,
                                  // width: "calc(20% - 8px)", // 20% width for 5 items per row
                                }}
                              >
                                {item.content}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>

                  <Droppable droppableId="droppable-container">
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          padding: "16px",
                          background: "#f4f4f4",
                          borderRadius: "8px",
                          // minHeight: "200px",
                          width: "600px", // Adjust the width for 5 items per row
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "8px",
                        }}
                      >
                        <span style={{ width: "100%", fontWeight: "bold" }}>
                          Selected Greetings
                        </span>
                        {droppedItems.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  padding: "8px",
                                  background: "#E0F2E0",
                                  border: "1px solid #E0F2E0",
                                  borderRadius: "4px",
                                  display: "flex",
                                  height: 40,
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  cursor: "grab",
                                  fontSize: 10,
                                  // width: "calc(20% - 8px)",
                                }}
                              >
                                {item.content}
                                <button
                                  onClick={() =>
                                    handleRemoveFromContainer(item)
                                  }
                                  style={{
                                    marginLeft: "8px",
                                    padding: "2px",
                                    background: "#ff4d4d",
                                    color: "#ffffff",
                                    border: "none",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                  }}
                                >
                                  ✕
                                </button>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
              <div
                style={{
                  // backgroundColor: "#E0F2E0",
                  padding: 5,
                  borderRadius: "4px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Style the Greetings here
                  </span>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 12,
                        color: "#1770e0",
                        display: "flx",
                        alignItems: "center",
                      }}
                    >
                      Not able to style the greeting?
                    </p>
                    <TooltipMaterial title="Please select your desired greeting and copy the line displayed in the editor. Then, paste the copied line into the content section while configuring the email body. Ensure that you do not remove or alter any keywords enclosed within {{ }} brackets, as these are essential for the proper functioning of the email content.">
                      <InfoIcon sx={{ color: "#1770e0" }} />
                    </TooltipMaterial>
                  </div>
                </div>

                <div>
                  {droppedItems.length > 0 ? (
                    // <p>{droppedItems.map((val) => val.content).join(" ")}</p>
                    <JoditEditorCustom
                      value={`<p>${droppedItems.map((val) => val.id).join(" ")}</p>`}
                      onChange={(e) => {
                        console.log("Asdjnasdas", e);
                        setCampaignGreetingData((prev) => ({
                          ...prev,
                          CAMPAIGN_GREETINGS_HTML: e,
                        }));
                      }}
                    />
                  ) : (
                    // <JoditEditorCustom
                    //   value={`<p>${droppedItems.map((val) => val.content).join(" ")}</p>`}
                    // />
                    "Please drag and drop greeting"
                  )}
                </div>
              </div>

              {/* <div className="row">
                <div className="col-4">
                  <Typography
                    sx={{
                      fontSize: 17,
                      color: "#000",
                      fontWeight: "bold",
                      textAlign: "start",
                      mt: 2,
                      fontFamily: "poppins",
                    }}
                  >
                    Greetings
                  </Typography>
                  <CustomDropdown
                    menuPlacement="top"
                    Placeholder={"Select Greetings"}
                    Style={{
                      height: 40,
                    }}
                    isSearchable={false}
                    Options={[
                      { label: "Hi", value: "OPENED" },
                      { label: "Hello", value: "BOUNCED" },
                      { label: "Hey", value: "Dear" },
                      { label: "Dear", value: "BOUNCED" },
                      { label: "Greetings", value: "BOUNCED" },
                      { label: "Warm greetings", value: "BOUNCED" },
                      { label: "My dear ", value: "BOUNCED" },
                      { label: "Dearest", value: "BOUNCED" },
                      { label: "Lovely", value: "BOUNCED" },
                      { label: "Hi friend", value: "BOUNCED" },
                      { label: "Hello my friend", value: "BOUNCED" },
                      { label: "Dear Sir/Madam", value: "BOUNCED" },
                      { label: "Good day", value: "BOUNCED" },
                      { label: "Congratulations", value: "BOUNCED" },
                      { label: "Welcome", value: "BOUNCED" },
                      { label: "Woohoo! Hi", value: "BOUNCED" },
                      { label: "Namaste", value: "BOUNCED" },
                      { label: "Hola", value: "BOUNCED" },
                      { label: "Good Morning", value: "BOUNCED" },
                      { label: "Good Afternoon", value: "BOUNCED" },
                      { label: "Good Evening", value: "BOUNCED" },
                      { label: "Hey there", value: "BOUNCED" },
                      { label: "Hiya", value: "BOUNCED" },
                      { label: "Howdy", value: "BOUNCED" },
                      { label: "Yo", value: "BOUNCED" },
                      { label: "What’s up", value: "BOUNCED" },
                      { label: "Hi team", value: "BOUNCED" },
                      { label: "Hello folks", value: "BOUNCED" },
                      { label: "Hey everyone", value: "BOUNCED" },
                    ]}
                    OnChange={(e) => {
                      setLast24HrPerformanceQuery((prev) => ({
                        ...prev,
                        EMAIL_STATUS: e,
                      }));
                    }}
                    Value={campaignSubjectData.FROM_EMAIL}
                  />
                </div>
                <div className="col-4">
                  <Typography
                    sx={{
                      fontSize: 17,
                      color: "#000",
                      fontWeight: "bold",
                      textAlign: "start",
                      mt: 2,
                      fontFamily: "poppins",
                    }}
                  >
                    Name
                  </Typography>
                  <CustomDropdown
                    menuPlacement="top"
                    Placeholder={"Select Name"}
                    Style={{
                      height: 40,
                    }}
                    isSearchable={false}
                    Options={[
                      { label: "Delivery", value: "DELIVERY" },
                      { label: "Opened", value: "OPENED" },
                      { label: "Bounced", value: "BOUNCED" },
                    ]}
                    OnChange={(e) => {
                      setLast24HrPerformanceQuery((prev) => ({
                        ...prev,
                        EMAIL_STATUS: e,
                      }));
                    }}
                    Value={campaignSubjectData.FROM_EMAIL}
                  />
                </div>
              </div> */}

              <div
                className="footer-buttons"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                }}
              >
                <button
                  className="bordered-button"
                  onClick={() => {
                    setPersonalizationDrawerState(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="filled-button"
                  onClick={() => {
                    campaignPersonalizationUpdate();
                  }}
                  style={{
                    minWidth: 100,
                  }}
                  disabled={transactionFlags.PERSONALIZATION_SAVE_LOADING}
                >
                  {transactionFlags.PERSONALIZATION_SAVE_LOADING == true ? (
                    <Spinner />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </Box>
          </SwipeableDrawer>
        </>
      )}

      {showCampaignDetailsPage.STATUS == true && (
        <Box
          // className="campaign-details-container"
          sx={{
            mt: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: 2,
              pb: 1,
            }}
          >
            <Box
              sx={{
                mr: 1,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                navigate("/view-campaign");
              }}
            >
              <IconContext.Provider value={{ size: 25, color: "#000" }}>
                <FaArrowLeftLong />
              </IconContext.Provider>
            </Box>
            <Typography
              style={{
                fontSize: 22,
                fontWeight: 600,
                color: "#000",
                marginRight: 10,
                textAlign: "start",
              }}
            >
              {campaignName.DEFAULT_NAME}{" "}
              <Chip
                label={
                  campaignStatus.find(
                    (item) => item.value == showCampaignDetailsPage.STATUS_CODE
                  )?.label || ""
                }
                // color="success"
                sx={{
                  backgroundColor: "#eaf8e4", // Light green background
                  color: "#2d572c", // Darker green text for contrast
                  fontWeight: "bold", // Make the text stand out
                  border: "1px solid #90ee90", // Border to match the green tone
                  borderRadius: "8px", // Rounded edges for a softer look
                  padding: "4px 12px", // Custom padding for better proportions
                }}
              />
            </Typography>
            {/* <h9 className="header-text">Company Data</h9> */}
          </Box>
          <Box sx={{ bgcolor: "rgb(91, 91, 240)" }}>
            <StyledTabs
              value={ReportSelectTab}
              onChange={(event, newValue) => {
                navigate(`?tab=${newValue}`, {
                  state: {
                    campaign_id: location.state.campaign_id,
                  },
                });
                setReportSelectTab(newValue);
              }}
              aria-label="styled tabs example"
            >
              <StyledTab label="Overview" />
              <StyledTab label="Click Performance" />
              <StyledTab label="Campaign Preview" />
              <StyledTab label="HTML Source" />
              <StyledTab label="Details" />
            </StyledTabs>
          </Box>
          {ReportSelectTab == 0 && (
            <>
              <Box className="campaign-analysis-grid-container">
                {campaignAnalysis.map((val) => {
                  return (
                    <div className="campaign-analysis-card">
                      <span className="campaign-analysis-time-text">
                        {val.VALUE}
                      </span>
                      <br />
                      <span className="campaign-analysis-text">
                        {val.TITLE}
                      </span>
                    </div>
                  );
                })}
              </Box>

              <Card
                sx={{
                  p: 1,
                  m: 1,
                  // pb: 10,
                  // height: 500,
                }}
              >
                <Box className="performance-container">
                  {/* Left Section */}
                  <Box className="left-section">
                    <Typography className="title">
                      24 hour performance snapshot
                    </Typography>
                    {/* <Typography className="subtitle">
                      Nov 17, 2024 - Nov 18, 2024
                    </Typography> */}
                  </Box>

                  {/* Right Section */}
                  <Box className="right-section">
                    {/* Email Status Dropdown */}
                    <Box className="dropdown-box">
                      <Typography className="dropdown-label">
                        Email Status
                      </Typography>
                      <CustomDropdown
                        isSearchable={false}
                        Options={[
                          { label: "Delivery", value: "DELIVERY" },
                          { label: "Opened", value: "OPENED" },
                          { label: "Bounced", value: "BOUNCED" },
                        ]}
                        Value={Last24HrPerformanceQuery.EMAIL_STATUS}
                        OnChange={(e) => {
                          setLast24HrPerformanceQuery((prev) => ({
                            ...prev,
                            EMAIL_STATUS: e,
                          }));
                        }}
                      />
                    </Box>

                    {/* Time Slot Dropdown */}
                    <Box className="dropdown-box">
                      <Typography className="dropdown-label">
                        Time slot
                      </Typography>
                      <CustomDropdown
                        isSearchable={false}
                        Options={[
                          { label: "30 Minutes", value: 30 },
                          { label: "1 Hour", value: 60 },
                          { label: "2 Hour", value: 120 },
                          { label: "4 Hour", value: 240 },
                        ]}
                        Value={Last24HrPerformanceQuery.INTERVAL}
                        OnChange={(e) => {
                          setLast24HrPerformanceQuery((prev) => ({
                            ...prev,
                            INTERVAL: e,
                          }));
                        }}
                      />
                    </Box>
                  </Box>
                </Box>

                <Line
                  style={{
                    width: "100%",
                  }}
                  data={{
                    labels: Last24HrPerformanceData.LABELS,
                    datasets: [
                      {
                        label: Last24HrPerformanceData.TITLE,
                        data: Last24HrPerformanceData.COUNT, // Example data
                        borderColor: Last24HrPerformanceData.COLOR, // Purple color
                        backgroundColor: Last24HrPerformanceData.COLOR,
                        pointBorderColor: Last24HrPerformanceData.COLOR,
                        pointBackgroundColor: "#fff",
                        pointHoverBackgroundColor:
                          Last24HrPerformanceData.COLOR,
                        pointHoverBorderColor: Last24HrPerformanceData.COLOR,
                        borderWidth: 2,
                        tension: 0.4, // Smooth curve
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: true,
                        position: "bottom",
                      },
                      title: {
                        display: true,
                        text: "24-hour performance snapshot",
                        font: {
                          size: 18,
                          weight: "bold",
                        },
                      },
                      tooltip: {
                        enabled: false, // Disable tooltips
                      },
                    },
                    scales: {
                      x: {
                        title: {
                          display: true,
                          text: "Time",
                        },
                      },
                      y: {
                        title: {
                          display: true,
                          text: "Percentage",
                        },
                        min: 0,
                        max: 100,
                        ticks: {
                          callback: (value) => `${value}%`,
                        },
                      },
                    },
                    elements: {
                      point: {
                        radius: 3, // Customize the dot radius
                        pointStyle: "circle", // Keep dots visible
                        hoverRadius: 4,
                      },
                      line: {
                        tension: 0.4, // Smooth line
                      },
                    },
                    plugins: {
                      datalabels: {
                        display: false, // Disable data labels entirely
                      },
                    },
                  }}
                />
              </Card>
            </>
          )}
          {ReportSelectTab == 1 && (
            <Box
              sx={{
                mt: 1,
              }}
            >
              <Card
                sx={{
                  p: 1,
                }}
              >
                <Box
                  className="as-inner-container"
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    mb: 0.5,
                  }}
                >
                  <div
                    className="fields-width"
                    style={{
                      minWidth: 200,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 18,
                        color: "#000",
                        textAlign: "start",
                      }}
                    >
                      Delivery Status
                    </Typography>
                    <CustomDropdown
                      Options={deliveryStatusArr}
                      Value={emailRecipientQuery.DELIVERY_STATUS}
                      OnChange={(e) => {
                        setEmailRecipientQuery((prev) => ({
                          ...prev,
                          DELIVERY_STATUS: e,
                        }));
                      }}
                    />
                  </div>
                  <div className="fields-width">
                    <Typography
                      style={{
                        fontSize: 18,
                        color: "#000",
                        textAlign: "start",
                      }}
                    ></Typography>
                    <br />
                    <button
                      className="filled-button"
                      onClick={handleOpenUserMenu}
                      style={{
                        minWidth: 100,
                      }}
                    >
                      Create Sub Campaign
                    </button>
                  </div>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMountedF
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {subCampaignOptions.map((val, index) => {
                      return (
                        <>
                          <MenuItem
                            onClick={() => {
                              setCampaignCreateModal(true);
                              setCreateCampaignData((prev) => ({
                                ...prev,
                                SELECTED_RECIPIENT_TYPE: val.value,
                              }));
                            }}
                          >
                            {val.label}
                          </MenuItem>
                          {subCampaignOptions.length - 1 != index && (
                            <Divider />
                          )}
                        </>
                      );
                    })}
                  </Menu>
                </Box>

                <ReactDataTable
                  columns={recipientCampaignColumns}
                  data={recipientData}
                  pageCount={9}
                />
              </Card>
            </Box>
          )}

          {ReportSelectTab == 2 && (
            <Box>
              <div style={{ textAlign: "center", margin: "20px" }}>
                {previewUrl?.HTML_PREVIEW && (
                  <div
                    style={{
                      marginTop: "20px",
                      border: "1px solid #ddd",
                      borderRadius: "5px",
                      overflow: "hidden",
                    }}
                  >
                    <iframe
                      src={previewUrl?.HTML_PREVIEW}
                      title="Link Preview"
                      style={{
                        width: "100%",
                        height: "500px",
                        border: "none",
                      }}
                    />
                  </div>
                )}
              </div>
            </Box>
          )}
          {ReportSelectTab == 3 && (
            // <Box>{previewUrl.HTML_TEXT}</Box>
            <div
              style={{
                border: "1px solid #ddd",
                borderRadius: "5px",
                backgroundColor: "#f9f9f9",
                padding: "10px",
                overflowX: "auto",
              }}
            >
              <div
                style={{
                  position: "relative",
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                  backgroundColor: "#f9f9f9",
                  padding: "10px",
                  overflowX: "auto",
                }}
              >
                {/* Copy Button */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    onClick={() => {
                      handleCopy(previewUrl?.HTML_TEXT);
                    }}
                    style={{
                      backgroundColor: copied ? "#4caf50" : "#007bff",
                      color: "#fff",
                      border: "none",
                      borderRadius: "3px",
                      padding: "5px 10px",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      cursor: "pointer",
                    }}
                  >
                    {copied ? (
                      <>
                        <CheckCircleIcon style={{ fontSize: "16px" }} />
                        Copied
                      </>
                    ) : (
                      <>
                        <ContentCopyIcon style={{ fontSize: "16px" }} />
                        Copy Code
                      </>
                    )}
                  </button>
                </div>

                {/* Syntax Highlighter */}
                <SyntaxHighlighter
                  language="html"
                  showLineNumbers
                  lineNumberStyle={{ color: "#999", marginRight: "10px" }}
                >
                  {previewUrl?.HTML_TEXT}
                </SyntaxHighlighter>
              </div>
            </div>
          )}

          {/* Details tab */}
          {ReportSelectTab == 4 && (
            <Box>
              <div className="campaign-report-wrapper">
                <div className="campaign-report-container">
                  <h1 className="campaign-report-heading">
                    Campaign Report Details
                  </h1>

                  <div className="campaign-detail">
                    <strong>Email URL:</strong>
                    <a
                      href="https://mailchi.mp/0442ea21bd45/hello"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {AXIOS.defaultPort + emailContentData.TEMPLATE_HTML}
                    </a>
                  </div>
                  <div className="campaign-detail">
                    <strong>Campaign Create at:</strong>
                    <p>
                      {campaignData.CREATED_TIME
                        ? new Date(campaignData.CREATED_TIME).toDateString()
                        : ""}{" "}
                      {campaignData.CREATED_TIME
                        ? new Date(campaignData.CREATED_TIME).toLocaleString(
                            "en-US",
                            {
                              hour: "numeric",
                              minute: "numeric",
                              second: "numeric",
                              hour12: true,
                            }
                          )
                        : "No Date Available"}
                    </p>
                  </div>
                  <div className="campaign-detail">
                    <strong>Campaign started date & time:</strong>
                    <p>
                      {campaignData.CAMPAIGN_START_TIME
                        ? new Date(
                            campaignData.CAMPAIGN_START_TIME
                          ).toDateString()
                        : ""}{" "}
                      {campaignData.CAMPAIGN_START_TIME
                        ? new Date(
                            campaignData.CAMPAIGN_START_TIME
                          ).toLocaleString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            hour12: true,
                          })
                        : "No Date Available"}
                    </p>
                  </div>
                  <div className="campaign-detail">
                    <strong>Delivery date & time:</strong>
                    <p>
                      {campaignData.CAMPAIGN_COMPLETE_TIME
                        ? new Date(
                            campaignData.CAMPAIGN_COMPLETE_TIME
                          ).toDateString()
                        : ""}{" "}
                      {campaignData.CAMPAIGN_COMPLETE_TIME
                        ? new Date(
                            campaignData.CAMPAIGN_COMPLETE_TIME
                          ).toLocaleString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            hour12: true,
                          })
                        : "No Date Available"}
                    </p>
                  </div>
                  <div className="campaign-detail">
                    <strong>From name:</strong>
                    <p>{emailContentData?.FROM_NAME}</p>
                  </div>
                  <div className="campaign-detail">
                    <strong>From email:</strong>
                    <a href={`mailto:${emailContentData?.FROM_EMAIL}`}>
                      {emailContentData?.FROM_EMAIL}
                    </a>
                  </div>
                  <div className="campaign-detail">
                    <strong>To:</strong>
                    <p>{recipientData && recipientData.length} Recipient</p>
                  </div>
                  <div className="campaign-detail">
                    <strong>Subject line:</strong>
                    <p>{emailContentData?.SUBJECT}</p>
                  </div>

                  <div className="campaign-detail">
                    <strong>Preview text:</strong>
                    <p>{emailContentData?.PREVIEW_TEXT}</p>
                  </div>
                </div>
              </div>
            </Box>
          )}
        </Box>
      )}
      <Modal
        open={campaignCreateModal}
        onClose={() => {
          setCampaignCreateModal(false);
          setCreateCampaignData((prev) => ({
            ...prev,
            CAMPAIGN_COMPLETED: false,
          }));
        }}
        disableAutoFocus
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="edit-modal-container"
          sx={{
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: 17,
                color: COLORS.gray70,
                fontWeight: "bold",
              }}
            >
              Create Sub Campaign
            </Typography>

            <div
              style={{
                cursor: "pointer",
              }}
            >
              <IconContext.Provider value={{ size: 15, marginTop: 5 }}>
                <RxCross1
                  onClick={() => {
                    setCampaignCreateModal(false);
                    setCreateCampaignData((prev) => ({
                      ...prev,
                      CAMPAIGN_COMPLETED: false,
                    }));
                  }}
                />
              </IconContext.Provider>
            </div>
          </Box>
          <Divider />

          {createCampaignData?.CAMPAIGN_COMPLETED == false && (
            <>
              <Box
                sx={{
                  mt: 2,
                }}
              >
                <Typography
                  style={{
                    fontSize: 15,
                    color: "#000",
                    textAlign: "start",
                  }}
                >
                  Sub Campaign Name
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <CustomInput
                  Placeholder={"Campaign Name"}
                  // icon={Icons.campaign}
                  Value={createCampaignData.CAMPAIGN_NAME}
                  onChange={(e) => {
                    setCreateCampaignData((prev) => ({
                      ...prev,
                      CAMPAIGN_NAME: e.target.value,
                      CAMPAIGN_NAME_ERROR: false,
                    }));
                  }}
                />
                {createCampaignData.CAMPAIGN_NAME_ERROR && (
                  <p className="helper-text">
                    Please enter Campaign Description
                  </p>
                )}

                <Typography
                  style={{
                    fontSize: 15,
                    color: "#000",
                    textAlign: "start",
                  }}
                >
                  Description
                </Typography>
                <div className="group">
                  <textarea
                    rows={5}
                    className="text-box-style"
                    style={{
                      height: 70,
                      fontFamily: "sans-serif",
                    }}
                    placeholder={"Description"}
                    // icon={Icons.campaign}
                    value={createCampaignData.NAME}
                    onChange={(e) => {
                      setCreateCampaignData((prev) => ({
                        ...prev,
                        CAMPAIGN_DESCRIPTION: e.target.value,
                        CAMPAIGN_DESCRIPTION_ERROR: false,
                      }));
                    }}
                  />
                </div>
                {createCampaignData.CAMPAIGN_DESCRIPTION_ERROR && (
                  <p className="helper-text">
                    Please enter Campaign Description
                  </p>
                )}
              </Box>
              <div
                className="as-inner-container"
                style={{
                  paddingTop: 20,
                  minHeight: 120,
                }}
              >
                {subCampaignOptions.map((val) => {
                  return (
                    <Card
                      onClick={() => {
                        setCreateCampaignData((prev) => ({
                          ...prev,
                          SELECTED_RECIPIENT_TYPE: val.value,
                        }));
                      }}
                      sx={{
                        p: 1,

                        border:
                          createCampaignData.SELECTED_RECIPIENT_TYPE ==
                          val.value
                            ? "2px solid #1770e0"
                            : "",
                        borderRadius: 3,

                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        <span>{val.label}</span>

                        {createCampaignData.SELECTED_RECIPIENT_TYPE ==
                          val.value && (
                          <CheckCircleIcon
                            sx={{
                              color: "#1770e0", // Blue color for the check mark
                              fontSize: 24, // Adjust size as needed
                              position: "absolute", // Absolute positioning
                              right: -2, // Distance from the right edge
                              top: 12, // Vertically center
                              transform: "translateY(-50%)", // Adjust to center relative to the card height
                            }}
                          />
                        )}
                      </div>
                      <span
                        style={{
                          fontSize: 13,
                          color: val.TOTAL_COUNT == 0 ? "red" : "#1770e0",
                        }}
                      >
                        Total Contacts: {val.TOTAL_COUNT}
                      </span>
                      {/* <br />
                      {val.TOTAL_COUNT == 0 && (
                        <span
                          style={{
                            color: "red",
                            fontSize: 13,
                          }}
                        >
                          (Not suggested)
                        </span>
                      )} */}
                    </Card>
                  );
                })}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  style={{
                    minWidth: 100,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    padding: 5,
                    marginTop: 10,
                    backgroundColor: "#fff",
                    color: "black",
                  }}
                  onClick={() => {
                    setCampaignCreateModal(false);
                  }}
                  className="signup-button"
                >
                  Cancel
                </button>
                <button
                  style={{
                    minWidth: 150,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    padding: 5,
                    marginTop: 10,
                    backgroundColor: "#1770e0",
                  }}
                  onClick={() => {
                    createSubCampaign();
                  }}
                  className="signup-button"
                >
                  Add Campaign
                  <CampaignIcon />
                </button>
              </div>
            </>
          )}
          {createCampaignData?.CAMPAIGN_COMPLETED == true && (
            <>
              <img
                src={EmailCampaignCreated}
                style={{
                  width: "100%",
                  height: 300,
                }}
              />
              <p
                className="text-means-web recipient-description"
                style={{
                  textAlign: "center",
                }}
              >
                The campaign has been created Successfully
              </p>
              <div
                className="footer-buttons"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                }}
              >
                <button
                  className="bordered-button"
                  onClick={() => {
                    setCampaignCreateModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="filled-button"
                  onClick={() => {
                    setCampaignCreateModal(false);
                    // if (createCampaignData.CAMPAIGN_ID) {
                    //   navigate("/campaign-details", {
                    //     state: {
                    //       campaign_id: createCampaignData.CAMPAIGN_ID,
                    //     },
                    //   });
                    // } else {
                    //   toast.error("Campaign ID not found");
                    // }
                  }}
                >
                  Close
                </button>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </MainScreen>
  );
}

// export default CampaignDetail;
const mapStateToProps = (state) => ({
  auth_email_id: state.contactData.auth_email_id,
  auth_name: state.contactData.auth_name,
  auth_id: state.contactData.auth_id,
});

export default connect(mapStateToProps, {})(CampaignDetail);
