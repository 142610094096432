import React, { useState, useNavigation, useEffect } from "react";
import "./ContactDetails.css";
import {
  Box,
  Card,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Table,
  TableRow,
  Paper,
  TableBody,
  TextField,
  Tooltip,
  Checkbox,
  Modal,
  Divider,
} from "@mui/material";
import useWindowDimensions from "../components/Hooks/windowDimensions";
import Icons from "../constants/Icons";
import { FaMobileAlt } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { IconContext } from "react-icons";
import { MdOutlineMail } from "react-icons/md";
import EmailIcon from "@mui/icons-material/Email";
import { COLORS } from "../constants/theme";
import { MdEdit } from "react-icons/md";
import CustomButton from "../components/CustomButton/CustomButton";
import CustomInput from "../components/CustomInput/CustomInput";
import { useNavigate, useLocation } from "react-router-dom";
// import cogoToast from "cogo-toast";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaHandshake } from "react-icons/fa";
import { FaCity } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";
import { IoAddCircleOutline } from "react-icons/io5";
import { ContactDataGetAction } from "../Redux/Action/ContactDataGetAction";
import { MeetingDataGetAction } from "../Redux/Action/MeetingDataGetAction";
import { EmployeeDetailsGetAction } from "../Redux/Action/EmployeeDetailsGetAction";
import { connect, useDispatch } from "react-redux";
import Lottie from "react-lottie-player";
import noData from "../assets/lottieFiles/noData.json";
import loadingAnime from "../assets/lottieFiles/loader.json";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import getAccessToken from "../constants/getAccessToken";
import clientData from "../constants/clientData";
import { AxiosDefaultPort } from "../constants/Axios";
import CustomDropdown from "../components/CustomDropDown/CustomDropDown";
import { engagementStatus, meetingMode } from "../utils/Constants";
import MainScreen from "../components/Navbar/MainScreen";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import BusinessIcon from "@mui/icons-material/Business";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import { DdOptionsGetAction } from "../Redux/Action/DdOptionsGetAction";
import validCountryCodes from "../constants/country_codes.json";

function ContactDetails(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  const [editModal, setEditModal] = useState(false);
  const [meetingModal, setMeetingModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [meetingData, setMeetingData] = useState([]);
  const [meetingEditModal, setMeetingEditModal] = useState(false);
  const [contactMeetingData, setContactMeetingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nameInit, setNameInit] = useState("");
  const [meetingDate, setMeetingDate] = useState("");
  const [meetingTime, setMeetingTime] = useState("");
  const [remarks, setRemarks] = useState("");
  const [meetingValID, setMeetingValID] = useState();
  const [empData, setEmpData] = useState({});
  const [stateData, setStateData] = useState({});

  const [cityList, setCityList] = useState({
    label: "City *",
    // value: { label: "", value: "" },
    value: "",
    type: "dropDown",
    options: [],
    ID: "city",
    table: "contact",
    emptyFlag: false,
  });

  useEffect(() => {
    if (props.employee_data != undefined || props.employee_data.length > 0) {
      let loggedEmp = props.employee_data.find(
        (val) => val.email_id == props.auth_email_id
      );
      setEmpData(loggedEmp);
    }
  }, [props.employee_data]);

  const [data, setData] = useState({
    ...location?.state?.searchContact,
    meetings: [],
  });
  const [countryCode, setCountryCode] = useState({
    value: "",
    emptyFlag: false,
  });
  const convertIndianStandardIntoYMD = (date) => {
    var dateObj = new Date(date);
    if (!isNaN(dateObj) && dateObj != "") {
      let mnth = ("0" + (dateObj?.getMonth() + 1)).slice(-2);
      let day = ("0" + dateObj?.getDate())?.slice(-2);
      return [day, mnth, dateObj.getFullYear()].join("-");
    }
  };

  const dateToUTCConvert = (date) => {
    var date = new Date(date);

    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
    let thisDate = new Date(now_utc);
    let theDate = thisDate.getDate();
    let theMonth = thisDate.getMonth() + 1;
    let theYear = thisDate.getFullYear().toString();
    if (theDate < 10) {
      theDate = "0" + theDate.toString();
    } else {
      theDate = theDate.toString();
    }

    if (theMonth < 10) {
      theMonth = "0" + theMonth.toString();
    } else {
      theMonth = theMonth.toString();
    }

    return theYear + "/" + theMonth + "/" + theDate;
  };

  useEffect(() => {
    setCompanyData(props.contact_data);
    setMeetingData(props.meeting_data);
    let mData = [...props.meeting_data];
    let tempData = mData.filter(
      (val) => val.contactID == location?.state?.searchContact.ID.toString()
    );
    let tempContactData = props.contact_data.find(
      (val) => val.ID.toString() == location?.state?.searchContact.ID.toString()
    );

    // tempData.map((val) => {
    //   if (tempContactData) {
    //     val.meetingByName = tempContactData.meetingByName;
    //   } else {
    //     val.meetingByName = "";
    //   }
    // });

    tempData?.sort(function (a, b) {
      if (b.meetingDate < a.meetingDate) {
        return -1;
      }
      if (b.meetingDate > a.meetingDate) {
        return 1;
      }
      return 0;
    });

    tempData.map((val) => {
      let date = val.meetingDate;
      date = date.replaceAll("/", "-").split("-").reverse().join("-");
      val.meetingDate = date;
    });

    // if (tempData?.length > 0) {
    //   tempData?.map((val) => {
    //     if (val?.meetingDate != null && val?.meetingDate != undefined) {
    //       let mDate = new Date(val.meetingDate);
    //       if (mDate != "Invalid Date") {
    //         let mtDate = new Date(val.meetingDate);
    //         let mtgDate = mtDate.setDate(mtDate.getDate() + 1);
    //         // mtgDate = new Date(mtgDate);
    //         val.meetingDate = new Date(mtgDate).toISOString();
    //         if (val.meetingDate != null) {
    //           val.meetingDate = val.meetingDate
    //             .substring(0, 10)
    //             .split("-")
    //             .reverse()
    //             .join("-");
    //         }
    //       }
    //       // val.meetingDate = convertIndianStandardIntoYMD(val.meetingDate)
    //     }
    //   });
    // }

    setContactMeetingData(tempData);
    if (props.contact_data.length > 0) {
      setData({
        ...tempContactData,
        meetings: [...tempData],
      });
    }
    if (tempContactData) {
      let nameStr = "";
      let name0str = tempContactData?.name0?.trim();
      if (name0str?.split(" ").length > 1) {
        console.log("sahdjasdjs", Array.from(name0str?.split(" ")[0])[0]);

        nameStr =
          Array.from(name0str?.split(" ")[0])[0].toUpperCase() +
          Array.from(name0str?.split(" ")[1])[0].toUpperCase();
      } else if (Array.from(name0str).length > 2) {
        nameStr =
          Array.from(name0str)[0].toUpperCase() +
          Array.from(name0str)[1].toUpperCase();
      } else {
        nameStr = name0str.toUpperCase();
      }
      setNameInit(nameStr);
    }
  }, [props]);

  const searchContact = location?.state?.searchContact;

  // const contactMeetingData = meetingData.filter(
  //   (val) =>
  //     val.name0 == searchContact.name0 &&
  //     val.email == searchContact.email &&
  //     val.mobile == searchContact.mobile
  // );

  const textStyle = {
    mt: 2,
    ml: 2,
    fontSize: 16,
    textAlign: "start",
    color: "#1770E0",
    wordBreak: "break-word", // Allows breaking long words into the next line
    whiteSpace: "normal",
  };

  // const data = {
  //   name: "Amit Kumar",
  //   designation: "CEO",
  //   company: "Hewlett Packard",
  //   mobile: "+91 9999999999",
  //   landline: "011 9999999999",
  //   linkedIn: "https://www.linkedin.com/feed/",
  //   city: "Noida",
  //   address: "Inorbit Mall, Sector-12,MG Road, Noida-120001",
  //   meetings: [
  //     {
  //       meetingDate: "27-05-2023",
  //       remarks: "Negotiations Underway",
  //       leasingMember: "Rupali Surve",
  //     },
  //     {
  //       meetingDate: "14-04-2023",
  //       remarks: "Follow-up meeting",
  //       leasingMember: "Rupali Surve",
  //     },
  //     {
  //       meetingDate: "31-03-2023",
  //       remarks: "Intro meeting",
  //       leasingMember: "Prasand Meher",
  //     },
  //   ],
  // };

  // const data = { ...searchContact, meetings: [...contactMeetingData] };
  const inputData = [
    {
      label: "CR Manager",
      value: {
        label: data.crManagerName,
        value: data.crManager,
        _id: data.crManager,
      },
      options: props.employee_data,
      type: "dropdown",
      ID: "crManager",
      emptyFlag: false,
      valid: true,
      require: true,
    },
    {
      label: "Level",
      value: {
        label: data.level,
        value: data.level,
      },
      options: [
        { label: "CXO", value: "CXO" },
        { label: "Senior Mgmt", value: "Senior Mgmt" },
        { label: "Mid Mgmt", value: "Mid Mgmt" },
        { label: "General", value: "General" },
      ],
      type: "dropdown",
      ID: "level",
      emptyFlag: false,
      valid: true,
      require: true,
    },
    {
      label: "Contact Name",
      value: data.name0,
      type: "input",
      ID: "name0",
      emptyFlag: false,
      require: true,
    },

    {
      label: "Designation",
      value: data.designation,
      type: "input",
      ID: "designation",
      emptyFlag: false,
      require: true,
    },

    {
      label: "Company",
      value: data.company,
      type: "input",
      ID: "company",
      emptyFlag: false,
      valid: true,
      require: true,
    },
    {
      label: "Email",
      value: data.email,
      type: "input",
      ID: "email",
      emptyFlag: false,
      valid: true,
      require: true,
    },

    {
      label: "Company Address",
      value: data.address,
      type: "input",
      ID: "address",
      emptyFlag: false,
      // require: true,
    },
    {
      label: "Mobile",
      value: data.mobile,
      type: "input",
      ID: "mobile",
      emptyFlag: false,
      valid: true,
      require: true,
    },

    {
      label: "State",
      value: {
        label: data.state,
        value: data.state,
      },
      options: [],
      type: "dropdown",
      ID: "state",
      emptyFlag: false,
      valid: true,
      require: true,
    },
    {
      label: "City",
      value: {
        label: data.city,
        value: data.city,
      },
      options: [],
      type: "dropdown",
      ID: "city",
      emptyFlag: false,
      valid: true,
      require: true,
    },
    {
      label: "Landline Number",
      value: data.landline,
      type: "input",
      ID: "landline",
      emptyFlag: false,
      require: false,
    },

    {
      label: "LinkedIn url",
      value: data.linkedIn,
      type: "input",
      ID: "linkedIn",
      emptyFlag: false,
      require: false,
    },
  ];
  const meetingDetails = [
    {
      label: "Date of Meeting",
      value: "",
      type: "calendar",
      ID: "meetingDate",
      emptyFlag: false,
      require: true,
    },

    {
      label: "Meeting Time",
      value: "",
      type: "time",
      ID: "meetingTime",
      emptyFlag: false,
      require: true,
    },

    {
      label: "Meeting By",
      value: "",
      type: "dropdown",
      ID: "meetingBy",
      menuPlacement: "top",
      emptyFlag: false,
      options: [],
      require: true,
    },
    {
      label: "Stage of engagement",
      value: "",
      type: "dropdown",
      ID: "engagementStatus",
      menuPlacement: "top",
      emptyFlag: false,
      options: engagementStatus,
      require: true,
    },
    {
      label: "Mode of Meeting",
      value: "",
      type: "dropdown",
      ID: "meetingMode",
      menuPlacement: "top",
      emptyFlag: false,
      options: meetingMode,
      require: true,
    },
    {
      label: "Remarks",
      value: "",
      type: "input",
      ID: "remarks",
      emptyFlag: false,
      require: false,
    },

    // {
    //   label: "Leasing Member",
    //   value: "",
    //   type: "input",
    //   ID: "leasingMember",
    //   emptyFlag: false,
    //   valid: true,
    // },
  ];
  const [inputList, setInputList] = useState(inputData);

  useEffect(() => {
    let temp = [...inputList];
    let stateInd = temp.findIndex((val) => val.ID == "state");
    temp[stateInd].value = { label: data.state, value: data.state };
    let cityInd = temp.findIndex((val) => val.ID == "city");
    temp[cityInd].value = { label: data.city, value: data.city };
    setInputList(temp);
  }, [data]);
  useEffect(() => {
    let temp = [...inputData];
    let tempDd = [...props.dd_options];

    if (temp.length > 0 && temp.find((x) => x.ID == "state").value != "") {
      let indindex = temp.findIndex((x) => x.ID == "city");
      let indData = tempDd.filter(
        (val) =>
          val.stateForCity == temp.find((x) => x.ID == "state")?.value?.label
      );
      temp[indindex].options = indData;

      // setCityList(temp[indindex]);
    }
    let tempState = temp.find((x) => x.ID == "state");
    tempState.options = props.dd_options
      .filter((val) => val.dropdownId == "state")
      .sort(function (a, b) {
        var textA = a.label.toUpperCase();
        var textB = b.label.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    tempState.value = { label: data.state, value: data.state };
    // setStateData(tempState);
  }, [props, inputData, data]);

  useEffect(() => {}, [props.contact_data]);
  const [meetingList, setMeetingList] = useState(meetingDetails);

  useEffect(() => {
    let tempMeetingList = [...meetingList];
    const meetingByIndex = tempMeetingList.findIndex(
      (item) => item.ID == "meetingBy"
    );

    tempMeetingList[meetingByIndex].options = props.employee_data;
    tempMeetingList[meetingByIndex].value = {
      label: props.auth_name,
      value: props.auth_email_id,
      _id: props.auth_id,
    };

    setMeetingList(tempMeetingList);
  }, [
    props.employee_data,
    props.auth_name,
    props.auth_email_id,
    props.auth_id,
  ]);

  const mailtoHref =
    "mailto:" + data.email + "?subject=SendMail&body=Description";
  const contactText = { fontSize: 14, color: COLORS.gray30 };
  const deleteContact = () => {
    setLoading(true);
    const deleteContactData = (token) => {
      // axios
      //   .post(
      //     `https://${clientData.tenant}/sites/${clientData.site}/_api/Web/Lists/getbytitle('contactmanagementlist')/items/getbyid('${searchContact.ID}')`,
      //     {
      //       __metadata: { type: "SP.Data.ContactmanagementlistListItem" },
      //       // designation: "asdas",
      //       isRemoved: "Yes",
      //     },

      //     {
      //       headers: {
      //         "X-HTTP-Method": "MERGE",
      //         accept: "application/json;odata=verbose",
      //         "content-type": "application/json;odata=verbose",
      //         "If-Match": "*",
      //         Authorization: "Bearer " + token,
      //       },
      //     }
      //   )

      axios
        .post(`${AxiosDefaultPort}apis/sharepoint/contactDeleteUpdate`, {
          // token: token,
          contactDataId: searchContact._id,
        })
        .then((response) => {
          if (contactMeetingData.length > 0) {
            contactMeetingData.map((val, index) => {
              // axios
              //   .delete(
              //     `https://${clientData.tenant}/sites/${clientData.site}/_api/Web/Lists/getbytitle('meetingDetails')/items/getbyid('${val.ID}')`,

              //     {
              //       headers: {
              //         accept: "application/json;odata=verbose",
              //         "content-type": "application/json;odata=verbose",
              //         "If-Match": "*",
              //         Authorization: "Bearer " + token,
              //       },
              //     }
              //   )
              axios
                .post(`${AxiosDefaultPort}apis/sharepoint/meetingDelete`, {
                  token,
                  deleteID: val.ID,
                })
                .then((response) => {
                  if (index == contactMeetingData.length - 1) {
                    setLoading(false);
                    toast.success("Contact deleted");
                    navigate("/searchPage");
                    let tempContact = [...props.contact_data];
                    tempContact = tempContact.filter(
                      (item) => item.ID != searchContact.ID.toString()
                    );
                    dispatch({
                      type: "CONTACT_DATA_GET",
                      payload: tempContact,
                    });
                  }
                })
                .catch((err) => {
                  setLoading(false);
                  toast.error("Contact could not be deleted");
                });
            });
          } else {
            toast.success("Contact deleted");

            let tempContact = [...props.contact_data];
            tempContact = tempContact.filter(
              (item) => item.ID != searchContact.ID
            );

            dispatch({
              type: "CONTACT_DATA_GET",
              payload: tempContact,
            });
            navigate("/searchPage");
          }
        });
    };
    getAccessToken(deleteContactData);
  };

  const addMeetingHandler = () => {
    let tempMeetingList = [...meetingList];

    let errorFound = false;

    tempMeetingList.map((val) => {
      if (val.value == "" && val.require == true) {
        val.emptyFlag = true;
        errorFound = true;
      }
    });
    setMeetingList(tempMeetingList);

    if (errorFound == false) {
      setLoading(true);
      setMeetingModal(false);

      const postMeetingData = (token) => {
        let meetingPayload = {
          contactID: data.ID.toString(),
          // name0: data.name0,
          // email: data.email,
          // mobile: data.mobile,
        };
        meetingList.map((val) => {
          if (val.ID == "meetingDate") {
            let date = dateToUTCConvert(val.value);

            meetingPayload[val.ID] = date;
          } else if (val.ID == "meetingBy") {
            meetingPayload[val.ID] = val?.value?._id;
          } else if (val.ID == "engagementStatus" || val.ID == "meetingMode") {
            meetingPayload[val.ID] = val?.value?.value;
          } else {
            meetingPayload[val.ID] = val.value;
          }
        });
        const [hours, minutes] = meetingPayload.meetingTime.split(":");
        let dateTime = new Date(meetingPayload?.meetingDate);
        dateTime = dateTime.setHours(hours, minutes);
        dateTime = new Date(dateTime);

        meetingPayload.meetingTime = dateTime;
        meetingPayload.createdBy = props.auth_id;
        // axios
        //   .post(
        //     `https://${clientData.tenant}/sites/${clientData.site}/_api/Web/Lists/getbytitle('meetingDetails')/items`,
        //     {
        //       __metadata: { type: "SP.Data.MeetingDetailsListItem" },
        //       // designation: "asdas",
        //       ...meetingPayload,
        //     },
        //     {
        //       headers: {
        //         "Content-Type": "application/json;odata=verbose",
        //         Accept: "application/json;odata=verbose",
        //         Authorization: "Bearer " + token,
        //       },
        //     }
        //   )
        // ------------------

        axios
          .post(`${AxiosDefaultPort}apis/sharepoint/meetingDataCreate`, {
            meetingPayload,
            token,
          })
          .then((response) => {
            toast.success("Meeting data added");
            props.MeetingDataGetAction();
            setMeetingList(meetingDetails);
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            toast.error("Something went wrong. Try again later");
          });
      };
      getAccessToken(postMeetingData);
    }
  };

  const editContactHandler = () => {
    const editContactData = (token) => {
      let contactPayload = {};
      let errorFound = false;
      let tempInputList = [...inputList];
      tempInputList.map((val) => {
        console.log(("sdjasdsaasdsadsa", contactPayload));
        if (val.value == "" && val.require == true) {
          val.emptyFlag = true;
          errorFound = true;
        } else {
          if (val.type == "dropdown" && val.ID == "crManager") {
            contactPayload[val.ID] = val.value._id;
          } else if (
            val.type == "dropdown" &&
            (val.ID == "city" || val.ID == "state")
          ) {
            contactPayload[val.ID] = val.value.label;
          } else if (val.ID == "mobile") {
            console.log("nfbmsdfsbdm", countryCode.value);
            console.log("nfbmsdfsbdm1", val.value);
            if (val.value) {
              contactPayload[val.ID] =
                val.value?.split("-").length < 2
                  ? "+" + countryCode.value + "-" + val.value
                  : val.value;
            } else {
              contactPayload[val.ID] = "";
            }
          } else {
            contactPayload[val.ID] = val.value.value;
          }

          if (val.type == "input" && val.ID != "mobile") {
            contactPayload[val.ID] = val.value;
          }
        }
      });

      console.log(("sdjasdsaasdsadsa", contactPayload));

      setInputList(tempInputList);
      function isValidEmail(email) {
        // Regular expression for validating email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      }
      let email = tempInputList.find((val) => val.ID == "email").value;
      let emailFlag = tempInputList.find((val) => val.ID == "email").emptyFlag;
      if (emailFlag == true && isValidEmail(email) == false) {
        errorFound = true;
        toast.error("Please enter valid email id or valid mobile number");
      }
      if (inputList.some((val) => val.require == true && val.valid == false)) {
        errorFound = true;
        toast.error("Please enter valid data");
      }
      if (countryCode.emptyFlag == true) {
        errorFound = true;
        toast.error("Please enter valid country code");
      }
      if (errorFound == false) {
        axios
          .post(`${AxiosDefaultPort}apis/sharepoint/contactUpdate`, {
            contactPayload,
            contactDataId: data.ID,
            token,
          })
          .then((response) => {
            if (response.data.INVALID_CHARS == true) {
              toast.error("Special characters not allowed");
            } else {
              toast.success("Contact detail edited");
              props.ContactDataGetAction(props.auth_id);
            }
            setEditModal(false);
            // setMeetingList(meetingDetails);
          });
      } else {
      }
    };
    getAccessToken(editContactData);
  };
  const updateMeetingHandler = () => {
    let meetingBy = meetingList.find((val) => val.ID == "meetingBy").value._id;
    let engagementStatus = meetingList.find(
      (val) => val.ID == "engagementStatus"
    ).value.value;
    let meetingMode = meetingList.find((val) => val.ID == "meetingMode").value
      .value;

    if (meetingDate.includes("NA")) {
      toast.error("Please enter Meeting Date");
    }
    if (meetingTime == "") {
      toast.error("Please enter Meeting Time");
    }
    if (meetingMode == "") {
      toast.error("Please enter Meeting Mode");
    }
    if (engagementStatus == "") {
      toast.error("Please enter Stage of engagement");
    }
    if (meetingBy == "") {
      toast.error("Please enter Meeting By");
    } else {
      let meetingUpdate = (token) => {
        const [hours, minutes] = meetingTime.split(":");
        let dateTime = new Date(meetingDate);
        dateTime = dateTime.setHours(hours, minutes);
        dateTime = new Date(dateTime);

        axios
          .post(`${AxiosDefaultPort}apis/sharepoint/meetingDataUpdate`, {
            meetingPayload: {
              meetingDate: meetingDate,
              remarks: remarks,
              meetingTime: dateTime,
              meetingBy: meetingBy,
              meetingMode: meetingMode,
              engagementStatus: engagementStatus,
              updatedBy: props.auth_id,
            },
            meetingDataId: meetingValID,
            token,
          })
          .then((response) => {
            if (response.data.INVALID_CHARS == true) {
              toast.error("Special characters not allowed");
            } else {
              props.ContactDataGetAction(props.auth_id);
              props.MeetingDataGetAction();
              toast.success("Meeting updated");
              setMeetingDate("");
              setRemarks("");
              setMeetingTime("");
              setMeetingEditModal(false);
              let tempMeeting = [...props.meeting_data];
              let index = tempMeeting.findIndex(
                (val) => val._id == meetingValID
              );
              tempMeeting[index].remarks = remarks;
              tempMeeting[index].meetingDate = meetingDate;
              tempMeeting[index].meetingTime = dateTime;
              dispatch({
                type: "MEETING_DATA_GET",
                payload: tempMeeting,
              });
            }
          });
      };
      getAccessToken(meetingUpdate);
    }
  };

  const resetMeetingList = () => {
    let tempMeetingList = [...meetingList];
    tempMeetingList.map((val) => {
      if (val.ID == "meetingBy") {
        val.value = {
          label: props.auth_name,
          value: props.auth_email_id,
          _id: props.auth_id,
        };
      } else {
        val.value = "";
      }
    });

    setMeetingList(tempMeetingList);
  };
  function extractTimeFromDate(date) {
    // Ensure the date is a valid Date object
    if (!(date instanceof Date) || isNaN(date)) {
      return null;
    }

    let hours = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");

    // Format the time as HH:MM
    return `${hours}:${minutes}`;
  }
  return (
    <MainScreen>
      <Box
        className="contact-details-container"
        sx={{
          minHeight: "90vh",
        }}
      >
        <div>
          <Toaster
            containerStyle={{
              top: 20,
              left: 20,
              bottom: 20,
              right: 20,
            }}
            gutter={24}
            toastOptions={{
              style: {
                border: "1px solid #grey",
                padding: "16px",
              },
              success: {
                style: {
                  background: "#c4fcb1",
                  opacity: 0.1,
                },
              },
            }}
          />
        </div>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 2,
            ml: 2,
            pt: 2,
            pb: 2,
          }}
        >
          <Box
            sx={{
              mr: 1,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <IconContext.Provider value={{ size: 25, color: "#000" }}>
              <FaArrowLeftLong />
            </IconContext.Provider>
          </Box>
          {/* <h9 className="header-text">Contact Data</h9> */}
          <Typography
            style={{
              fontSize: 22,
              fontWeight: 600,
              color: "#000",
              marginRight: 10,
              textAlign: "start",
            }}
          >
            Contact Data{" "}
          </Typography>
        </Box>
        {location.state && (
          <Box className="contact-detail-box">
            <Box className="contact-container">
              <Box className="profile-container">
                <Box className="row-flex-card">
                  <Box>
                    <Box className="row-flex-i">
                      <Box className="absolute-row-flex">
                        <Box className="profile-circle">
                          <Typography
                            sx={{
                              color: "#996d3d",
                              fontSize: 27,
                              fontWeight: "bold",
                            }}
                          >
                            {/* {Array.from(
                          data?.name0?.split(" ")[0]
                        )[0].toUpperCase() +
                          Array.from(
                            data?.name0?.split(" ")[1]
                          )[0].toUpperCase()} */}
                            {nameInit}
                          </Typography>
                        </Box>
                        {(props?.role.includes("admin") ||
                          props?.role.includes("edit") ||
                          props?.role.includes("editDelete")) && (
                          <Tooltip title="Edit Contact">
                            <Box
                              className="edit-container"
                              onClick={() => {
                                setEditModal(true);
                                let codeData = data.mobile.split("-");
                                let code =
                                  codeData.length > 1
                                    ? data.mobile.split("-")[0].trim()
                                    : "";
                                code = code.replace(/^\+/, "");
                                let temp = [...inputData];
                                let emailInd = inputData.findIndex(
                                  (val) => val.ID == "email"
                                );
                                let mobileInd = inputData.findIndex(
                                  (val) => val.ID == "mobile"
                                );
                                var regex = /\S+@\S+\.\S+/;
                                if (regex.test(temp[emailInd].value)) {
                                  temp[mobileInd].emptyFlag = "NM";
                                  temp[mobileInd].require = false;
                                }
                                console.log("kjdfsdhfj", countryCode);

                                let phoneData = validCountryCodes.find(
                                  (val) => val.phone == code
                                );
                                let str =
                                  inputData
                                    .find((val) => val.ID == "mobile")
                                    ?.value?.split("-").length > 1
                                    ? inputData
                                        .find((val) => val.ID == "mobile")
                                        ?.value?.split("-")[1]
                                    : inputData
                                        .find((val) => val.ID == "mobile")
                                        ?.value?.split("-")[0];
                                let phoneValid = phoneData
                                  ? str.replace(/\s/g, "").length ==
                                    phoneData.phoneLength
                                  : false;
                                if (phoneValid == true) {
                                  temp[emailInd].emptyFlag = "NM";
                                  temp[emailInd].require = false;
                                }
                                setInputList(inputData);

                                setCountryCode((prev) => ({
                                  ...prev,
                                  emptyFlag: false,
                                  value: code,
                                }));
                              }}
                            >
                              <IconContext.Provider
                                value={{ size: 14, color: COLORS.white }}
                              >
                                <MdEdit />
                              </IconContext.Provider>
                            </Box>
                          </Tooltip>
                        )}
                      </Box>

                      <Box sx={{ ml: 3 }}>
                        <Box className="row-flex">
                          <Typography sx={{ fontSize: 18 }}>
                            {data.name0}
                          </Typography>

                          {/* <a href={data.linkedIn}> */}
                          {data.linkedIn && (
                            <Box
                              onClick={() =>
                                window.open(
                                  data.linkedIn,
                                  "_blank",
                                  "noreferrer"
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                // className="ln-img"
                                style={{
                                  marginRight: 10,
                                  height: 20,
                                  width: 20,
                                  marginLeft: 10,
                                }}
                                src={Icons.linkedIn}
                                alt=""
                                className="img-responsive"
                                tintColor="#fff"
                              />
                            </Box>
                          )}
                        </Box>
                        <Typography sx={{ ...contactText }}>
                          {data.designation}
                        </Typography>
                        <Typography sx={{ ...contactText }}>
                          {data.company}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      {data.email && (
                        <Box
                          className="row-flex-end mar-left"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 2,
                          }}
                        >
                          <EmailIcon
                            sx={{
                              color: "gray",
                            }}
                          />

                          <a
                            href={mailtoHref}
                            style={{
                              textDecoration: "none",
                            }}
                          >
                            <Typography sx={{ ...textStyle, mt: 0 }}>
                              {data.email}
                            </Typography>
                          </a>
                        </Box>
                      )}
                      {data.mobile && (
                        <Box
                          className="row-flex-end mar-left"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            window.open("tel:" + data.mobile);
                          }}
                        >
                          {/* <IconContext.Provider
                            value={{ size: 19, color: COLORS.gray40 }}
                          > */}
                          <PhoneIphoneIcon
                            sx={{
                              color: "gray",
                            }}
                          />
                          {/* </IconContext.Provider> */}

                          <Typography sx={{ ...textStyle }}>
                            {data.mobile}
                          </Typography>
                        </Box>
                      )}
                      {data.landline && (
                        <Box className="row-flex-end mar-left">
                          {/* <Box style={{ }}> */}
                          {/* <IconContext.Provider
                              value={{ size: 15, color: COLORS.gray40 }}
                            > */}
                          <PhoneInTalkIcon
                            sx={{
                              color: "gray",
                            }}
                          />
                          {/* </IconContext.Provider> */}
                          {/* </Box> */}

                          <Typography sx={{ ...textStyle }}>
                            {data.landline}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    {data.city && (
                      <Box
                        sx={{
                          borderRadius: 1,
                          mt: 2,
                        }}
                      >
                        <Box className="row-flex mar-left">
                          {/* <IconContext.Provider
                            value={{ size: 20, color: COLORS.gray40 }}
                          > */}
                          <BusinessIcon
                            sx={{
                              color: "gray",
                            }}
                          />
                          {/* </IconContext.Provider> */}
                          <Typography
                            sx={{ fontSize: 15, color: COLORS.gray60, ml: 2 }}
                          >
                            {data.city}
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    <Box className="addr-container mar-left">
                      <Typography sx={{ fontSize: 15, color: COLORS.gray60 }}>
                        {data.address}
                      </Typography>
                    </Box>

                    <Card
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip title="CR Manager" className="">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <IconContext.Provider
                            value={{ size: 20, color: "#1770E0" }}
                          >
                            <FaHandshake />
                          </IconContext.Provider>{" "}
                          <span
                            style={{
                              fontSize: 15,
                              fontWeight: "bold",
                              color: "#1770E0",
                              marginLeft: 5,
                            }}
                          >
                            CR manager
                          </span>
                        </div>
                        <Divider />
                        <div
                          style={{
                            display: "flex",
                            marginTop: 5,
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 15,
                              color: COLORS.gray60,
                              fontWeight: "bold",
                            }}
                          >
                            {data.crManagerName}
                          </Typography>
                        </div>
                      </Tooltip>
                    </Card>
                  </Box>

                  {/* table inside */}
                  <Box className="table-box inside-card">
                    <TableContainer
                      sx={{
                        maxHeight: "52vh",
                        minHeight: "20vh",
                        border: "1px solid #b7b7b7",
                      }}
                    >
                      <Table
                        enableStickyHeader
                        aria-label="sticky table"
                        // sx={{ border: "1px solid #b7b7b7" }}
                      >
                        <TableHead
                          sx={
                            {
                              // border: "1.5px solid #b7b7b7",
                            }
                          }
                        >
                          <tr
                            sx={{
                              "& .MuiTableCell-root": {
                                border: "none",
                                color: "#333",
                                fontWeight: 600,
                                fontSize: 17,
                                backgroundColor: "#ebe9d0",
                                textAlign: "center",
                                // width: 250,
                              },
                            }}
                            component={Paper}
                          >
                            <th
                              style={{ minWidth: 120 }}
                              className="contact-details-th"
                            >
                              Date of Meeting
                            </th>
                            <th className="contact-details-th">Meeting Time</th>
                            <th className="contact-details-th">Remarks</th>
                            <th
                              sx={{ maxWidth: 75 }}
                              className="contact-details-th"
                            >
                              Meeting By
                            </th>
                            <th
                              sx={{ maxWidth: 75 }}
                              className="contact-details-th"
                            >
                              Mode of Meeting
                            </th>
                            <th
                              sx={{ maxWidth: 75 }}
                              className="contact-details-th"
                            >
                              {" "}
                              Stage of engagement
                            </th>
                          </tr>
                        </TableHead>

                        <TableBody>
                          {data.meetings.map((val, index) => {
                            return (
                              <>
                                <tr
                                  className="Row"
                                  sx={{
                                    "&:hover": {
                                      background: "#e5e6e7",
                                    },
                                    backgroundColor:
                                      index % 2 == 0 ? "#f2f6fb" : "#fdfefe",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    // fontSize: 24,
                                  }}
                                >
                                  <td
                                    style={{
                                      textAlign: "center",
                                      fontSize: 16,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        justifyContent:
                                          props?.role.includes("admin") ||
                                          props?.role.includes("view") ||
                                          props?.role.includes("admin") ||
                                          props?.role.includes("editDelete")
                                            ? "space-between"
                                            : "center",
                                      }}
                                    >
                                      {(props?.role.includes("admin") ||
                                        props?.role.includes("editDelete") ||
                                        props?.role.includes("view")) && (
                                        <Tooltip title="Edit Meeting">
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              cursor: "pointer",
                                            }}
                                            // className="edit-container"
                                            onClick={() => {
                                              var mDate;
                                              var yy;
                                              var dd;
                                              var mm;
                                              let tempMeetingList = [
                                                ...meetingList,
                                              ];
                                              const meetingByIndex =
                                                tempMeetingList.findIndex(
                                                  (val) => val.ID == "meetingBy"
                                                );
                                              tempMeetingList[
                                                meetingByIndex
                                              ].value = {
                                                label: val.meetingByName,
                                                _id: val.meetingBy,
                                                value: val.meetingBy,
                                              };

                                              const meetingModeIndex =
                                                tempMeetingList.findIndex(
                                                  (val) =>
                                                    val.ID == "meetingMode"
                                                );
                                              tempMeetingList[
                                                meetingModeIndex
                                              ].value = {
                                                label:
                                                  meetingMode.find(
                                                    (item) =>
                                                      item.value ==
                                                      val.meetingMode
                                                  )?.label || "",
                                                value: val.meetingMode,
                                              };
                                              const engagementStatusIndex =
                                                tempMeetingList.findIndex(
                                                  (val) =>
                                                    val.ID == "engagementStatus"
                                                );
                                              tempMeetingList[
                                                engagementStatusIndex
                                              ].value = {
                                                label:
                                                  engagementStatus.find(
                                                    (item) =>
                                                      item.value ==
                                                      val.engagementStatus
                                                  )?.label || "",
                                                value: val.engagementStatus,
                                              };
                                              setMeetingList(tempMeetingList);

                                              if (
                                                !val.meetingDate?.includes(
                                                  "NaN"
                                                )
                                              ) {
                                                mDate =
                                                  val.meetingDate?.split("-");
                                                mm = mDate[1];

                                                if (Number(mDate[0]) > 31) {
                                                  yy = mDate[0];
                                                  dd = mDate[2];
                                                } else if (
                                                  Number(mDate[0]) <= 31
                                                ) {
                                                  dd = mDate[0];
                                                  yy = mDate[2];
                                                }
                                              }
                                              setMeetingValID(val._id);
                                              setMeetingEditModal(true);
                                              setMeetingDate(
                                                val.meetingDate?.includes("NaN")
                                                  ? "NA"
                                                  : yy + "-" + mm + "-" + dd
                                              );

                                              let tempMeetingTime =
                                                extractTimeFromDate(
                                                  new Date(val.meetingTime)
                                                );

                                              setMeetingTime(tempMeetingTime);

                                              setRemarks(
                                                val.remarks == null
                                                  ? "NA"
                                                  : val.remarks
                                              );
                                            }}
                                          >
                                            <IconContext.Provider
                                              value={{
                                                size: 14,
                                                color: COLORS.gray40,
                                              }}
                                            >
                                              <MdEdit />
                                            </IconContext.Provider>
                                          </Box>
                                        </Tooltip>
                                      )}
                                      <Typography
                                        sx={{
                                          ml: 1,
                                        }}
                                      >
                                        {val.meetingDate?.includes("NaN")
                                          ? "NA"
                                          : val.meetingDate}
                                      </Typography>
                                      <Box sx={{ width: 7 }} />
                                    </Box>
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      fontSize: 16,
                                    }}
                                  >
                                    {val.meetingTime == null
                                      ? "NA"
                                      : new Date(
                                          val.meetingTime
                                        ).toLocaleTimeString("en-US", {
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        })}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      fontSize: 16,
                                    }}
                                  >
                                    {val.remarks == null ? "NA" : val.remarks}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      fontSize: 16,
                                      padding: 10,
                                    }}
                                  >
                                    {val.meetingByName}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      fontSize: 16,
                                      padding: 10,
                                    }}
                                  >
                                    {meetingMode.find(
                                      (item) => item.value == val.meetingMode
                                    )?.label || ""}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      fontSize: 16,
                                      padding: 10,
                                    }}
                                  >
                                    {engagementStatus.find(
                                      (item) =>
                                        item.value == val.engagementStatus
                                    )?.label || ""}
                                  </td>
                                </tr>
                                {/* ---------- */}
                                <Modal
                                  open={meetingEditModal}
                                  onClose={() => {
                                    setMeetingEditModal(false);
                                    resetMeetingList();
                                  }}
                                  disableAutoFocus
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                  BackdropProps={{
                                    style: {
                                      backgroundColor: "rgba(0, 0, 0, 0.1)",

                                      // Adjust opacity as needed
                                    },
                                  }}
                                  // style={{ overflowY: "scroll", top: "5%", paddingTop: 15 }}
                                >
                                  <Box className="edit-modal-container">
                                    <Box
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "end",
                                        marginTop: 10,
                                        marginRight: 15,
                                        cursor: "pointer",
                                      }}
                                    >
                                      <IconContext.Provider
                                        value={{ size: 15, marginTop: 5 }}
                                      >
                                        <RxCross1
                                          onClick={() => {
                                            setMeetingEditModal(false);
                                            resetMeetingList();
                                          }}
                                        />
                                      </IconContext.Provider>
                                    </Box>
                                    <Typography
                                      sx={{
                                        pl: 2,
                                        fontSize: 17,
                                        ml: 2,
                                        color: COLORS.gray70,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Edit Meeting Details
                                    </Typography>
                                    <Box className="mtng-edit-box" />
                                    <Box className="handle-scroll">
                                      <Box sx={{ p: 1, ml: 2, mr: 1 }}>
                                        {meetingList.map((inVal, index) => {
                                          return (
                                            <>
                                              {inVal.type == "time" && (
                                                <>
                                                  <Typography
                                                    style={{
                                                      fontSize: 15,
                                                      color: COLORS.gray50,
                                                    }}
                                                  >
                                                    {inVal.label}
                                                  </Typography>
                                                  <CustomInput
                                                    Type={"time"}
                                                    // Disabled={val.ID == "leasingMember" ? true : false}
                                                    error={val.emptyFlag}
                                                    Value={meetingTime}
                                                    onChange={(e) => {
                                                      if (
                                                        inVal.ID ==
                                                        "meetingDate"
                                                      ) {
                                                        setMeetingDate(
                                                          e.target.value
                                                        );
                                                      } else if (
                                                        inVal.ID == "remarks"
                                                      ) {
                                                        setRemarks(
                                                          e.target.value
                                                        );
                                                      } else if (
                                                        inVal.ID ==
                                                        "meetingTime"
                                                      ) {
                                                        setMeetingTime(
                                                          e.target.value
                                                        );
                                                      }

                                                      let temp = [
                                                        ...meetingList,
                                                      ];
                                                      if (
                                                        inVal.ID ==
                                                        "meetingDate"
                                                      ) {
                                                      }
                                                      temp[index].value =
                                                        e.target.value;
                                                      // }

                                                      if (
                                                        temp[index].value == ""
                                                      ) {
                                                        temp[index].emptyFlag =
                                                          true;
                                                      } else {
                                                        temp[index].emptyFlag =
                                                          false;
                                                      }
                                                      if (temp[index].value) {
                                                        temp[index].emptyFlag =
                                                          false;
                                                      }
                                                      setMeetingList(temp);
                                                    }}
                                                  />
                                                </>
                                              )}

                                              {inVal.type != "dropdown" &&
                                                inVal.type != "time" && (
                                                  <>
                                                    <Typography
                                                      style={{
                                                        fontSize: 15,
                                                        color: COLORS.gray50,
                                                      }}
                                                    >
                                                      {inVal.label}
                                                    </Typography>
                                                    <CustomInput
                                                      error={inVal.emptyFlag}
                                                      // Value={meetingList[index].value}
                                                      Value={
                                                        inVal.ID ==
                                                        "meetingDate"
                                                          ? meetingDate
                                                          : remarks
                                                      }
                                                      Type={
                                                        inVal.type == "input"
                                                          ? "text"
                                                          : "date"
                                                      }
                                                      onChange={(e) => {
                                                        if (
                                                          inVal.ID ==
                                                          "meetingDate"
                                                        ) {
                                                          setMeetingDate(
                                                            e.target.value
                                                          );
                                                        } else if (
                                                          inVal.ID == "remarks"
                                                        ) {
                                                          setRemarks(
                                                            e.target.value
                                                          );
                                                        }
                                                        let temp = [
                                                          ...meetingList,
                                                        ];
                                                        if (
                                                          inVal.ID ==
                                                          "meetingDate"
                                                        ) {
                                                        }
                                                        temp[index].value =
                                                          e.target.value;
                                                        // }

                                                        if (
                                                          temp[index].value ==
                                                          ""
                                                        ) {
                                                          temp[
                                                            index
                                                          ].emptyFlag = true;
                                                        } else {
                                                          temp[
                                                            index
                                                          ].emptyFlag = false;
                                                        }
                                                        if (temp[index].value) {
                                                          temp[
                                                            index
                                                          ].emptyFlag = false;
                                                        }
                                                        setMeetingList(temp);
                                                      }}
                                                    />
                                                  </>
                                                )}
                                              {inVal.type == "dropdown" && (
                                                <>
                                                  <Typography
                                                    style={{
                                                      fontSize: 15,
                                                      color: COLORS.gray50,
                                                      marginTop: 10,
                                                    }}
                                                  >
                                                    {inVal.label}
                                                  </Typography>
                                                  <CustomDropdown
                                                    menuPlacement={
                                                      inVal?.menuPlacement
                                                    }
                                                    Label={inVal.label}
                                                    Options={inVal.options}
                                                    Value={inVal.value}
                                                    error={inVal.emptyFlag}
                                                    OnChange={(e) => {
                                                      let temp = [
                                                        ...meetingList,
                                                      ];
                                                      temp[index].value = e;
                                                      setMeetingList(temp);
                                                    }}
                                                  />
                                                  {inVal.emptyFlag == true && (
                                                    <>
                                                      <Typography
                                                        style={{
                                                          fontSize: 15,
                                                          color: COLORS.red1,
                                                        }}
                                                      >
                                                        Please enter{" "}
                                                        {inVal.label}
                                                      </Typography>
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          );
                                        })}
                                      </Box>
                                      <Box className="edit-button">
                                        <Typography
                                          style={{
                                            fontSize: 15,
                                            color: COLORS.gray70,
                                            marginRight: 10,
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setMeetingEditModal(false);
                                          }}
                                        >
                                          Cancel
                                        </Typography>
                                        <CustomButton
                                          Label="Update"
                                          buttonColor="#1770E0"
                                          style={{
                                            width: 80,
                                            height: 25,
                                            borderRadius: 5,
                                          }}
                                          labelStyle={{
                                            color: "#fff",
                                            fontSize: 15,
                                          }}
                                          onClick={() => updateMeetingHandler()}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Modal>
                                {/* ---------- */}
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {(props?.role.includes("admin") ||
                      props?.role.includes("editDelete") ||
                      props?.role.includes("view")) && (
                      <Tooltip title="Add meeting">
                        <Box
                          className="add-meeting-box"
                          onClick={() => {
                            let tempMeet = JSON.parse(
                              JSON.stringify(meetingDetails)
                            );
                            let index = tempMeet.findIndex(
                              (val) => val.ID == "meetingBy"
                            );
                            tempMeet[index].value = {
                              label: props.auth_name,
                              value: props.auth_id,
                            };
                            setMeetingList(tempMeet);
                            setMeetingModal(true);
                          }}
                        >
                          <IconContext.Provider value={{ size: 25 }}>
                            <IoAddCircleOutline />
                          </IconContext.Provider>
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
                <Box className="delete-box">
                  {props?.role.length > 0 &&
                    (props?.role.includes("admin") ||
                      props?.role.includes("delete") ||
                      props?.role.includes("editDelete")) && (
                      <Tooltip title="Delete Contact">
                        <img
                          style={{
                            marginLeft: 10,
                            height: 25,
                            width: 22,
                            cursor: "pointer",
                          }}
                          src={Icons.del}
                          alt=""
                          className="img-responsive"
                          onClick={() => {
                            setDeleteModal(true);
                          }}
                        />
                      </Tooltip>
                    )}
                </Box>
              </Box>
            </Box>
            {/* table outside */}
            <Box className="table-box-without-card">
              <TableContainer
                sx={{
                  maxHeight: "52vh",
                  minHeight: "20vh",
                  // border: "1px solid #b7b7b7",
                  backgroundColor: "#fff",
                }}
              >
                <Table
                  enableStickyHeader
                  aria-label="sticky table"
                  // sx={{ border: "1px solid #b7b7b7" }}
                >
                  <TableHead
                    sx={
                      {
                        // border: "1.5px solid #b7b7b7",
                      }
                    }
                  >
                    <tr>
                      <th sx={{ width: "20vh" }}>Date of Meeting</th>
                      <th>Meeting Time</th>
                      <th>Remarks</th>
                      <th sx={{ width: "20vh" }}>Meeting By</th>
                      <th sx={{ maxWidth: 75 }}>Mode of Meeting</th>
                      <th sx={{ maxWidth: 75 }}>Stage of engagement</th>
                    </tr>
                  </TableHead>

                  <TableBody>
                    {data.meetings.map((val, index) => {
                      return (
                        <tr
                          className="Row"
                          sx={{
                            "&:hover": {
                              background: "#e5e6e7",
                            },
                            backgroundColor:
                              index % 2 == 0 ? "#f2f6fb" : "#fdfefe",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            // fontSize: 24,
                          }}
                        >
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: 16,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent:
                                  props?.role.includes("admin") ||
                                  props?.role.includes("editDelete") ||
                                  props?.role.includes("view")
                                    ? "space-between"
                                    : "center",
                              }}
                            >
                              {(props?.role.includes("admin") ||
                                props?.role.includes("editDelete") ||
                                props?.role.includes("view")) && (
                                <Tooltip title="Edit Meeting">
                                  <Box
                                    // className="edit-container"
                                    onClick={() => {
                                      var mDate;
                                      var yy;
                                      var dd;
                                      var mm;
                                      let tempMeetingList = [...meetingList];
                                      const meetingByIndex =
                                        tempMeetingList.findIndex(
                                          (val) => val.ID == "meetingBy"
                                        );
                                      tempMeetingList[meetingByIndex].value = {
                                        label: val.meetingByName,
                                        _id: val.meetingBy,
                                        value: val.meetingBy,
                                      };

                                      const meetingModeIndex =
                                        tempMeetingList.findIndex(
                                          (val) => val.ID == "meetingMode"
                                        );
                                      tempMeetingList[meetingModeIndex].value =
                                        {
                                          label:
                                            meetingMode.find(
                                              (item) =>
                                                item.value == val.meetingMode
                                            )?.label || "",
                                          value: val.meetingMode,
                                        };
                                      const engagementStatusIndex =
                                        tempMeetingList.findIndex(
                                          (val) => val.ID == "engagementStatus"
                                        );
                                      tempMeetingList[
                                        engagementStatusIndex
                                      ].value = {
                                        label:
                                          engagementStatus.find(
                                            (item) =>
                                              item.value == val.engagementStatus
                                          )?.label || "",
                                        value: val.engagementStatus,
                                      };

                                      setMeetingList(tempMeetingList);

                                      if (!val.meetingDate?.includes("NaN")) {
                                        mDate = val.meetingDate?.split("-");
                                        mm = mDate[1];

                                        if (Number(mDate[0]) > 31) {
                                          yy = mDate[0];
                                          dd = mDate[2];
                                        } else if (Number(mDate[0]) <= 31) {
                                          dd = mDate[0];
                                          yy = mDate[2];
                                        }
                                      }
                                      setMeetingValID(val._id);
                                      setMeetingEditModal(true);
                                      setMeetingDate(
                                        val.meetingDate?.includes("NaN")
                                          ? "NA"
                                          : yy + "-" + mm + "-" + dd
                                      );

                                      let tempMeetingTime = extractTimeFromDate(
                                        new Date(val.meetingTime)
                                      );

                                      setMeetingTime(tempMeetingTime);

                                      setRemarks(
                                        val.remarks == null ? "NA" : val.remarks
                                      );
                                    }}
                                  >
                                    <IconContext.Provider
                                      value={{
                                        size: 14,
                                        color: COLORS.gray40,
                                      }}
                                    >
                                      <MdEdit />
                                    </IconContext.Provider>
                                  </Box>
                                </Tooltip>
                              )}
                              <Typography>
                                {val.meetingDate?.includes("NaN")
                                  ? "NA"
                                  : val.meetingDate}
                              </Typography>
                              <Box sx={{ width: 7 }} />
                            </Box>
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: 16,
                            }}
                          >
                            {val.meetingTime == null
                              ? "NA"
                              : new Date(val.meetingTime).toLocaleTimeString(
                                  "en-US",
                                  {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  }
                                )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: 16,
                            }}
                          >
                            {val.remarks == null ? "NA" : val.remarks}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: 16,
                              padding: 10,
                            }}
                          >
                            {val.meetingByName}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: 16,
                              padding: 10,
                            }}
                          >
                            {meetingMode.find(
                              (item) => item.value == val.meetingMode
                            )?.label || ""}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: 16,
                              padding: 10,
                            }}
                          >
                            {engagementStatus.find(
                              (item) => item.value == val.engagementStatus
                            )?.label || ""}
                          </td>
                        </tr>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Tooltip title="Add meeting">
                <Box
                  className="add-meeting-box"
                  onClick={() => {
                    setMeetingModal(true);
                  }}
                >
                  <IconContext.Provider value={{ size: 25 }}>
                    <IoAddCircleOutline />
                  </IconContext.Provider>
                </Box>
              </Tooltip>
            </Box>
          </Box>
        )}
        {!location.state && (
          <Box
            className="no-data"
            style={{
              backgroundColor: "#fff",
              width: "80%",
              margin: "auto",
              height: "60vh",
            }}
          >
            <Lottie
              play
              //   speed={"1"}
              animationData={noData}
              style={{
                width: 250,
                height: 250,
              }}
            />
            <Typography
              sx={{ fontSize: 20, fontWeight: 500, color: "#b7beca" }}
            >
              No Contact Found
            </Typography>
          </Box>
        )}
        <Modal
          open={editModal}
          onClose={() => {
            setEditModal(false);
          }}
          disableAutoFocus
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          // style={{ overflowY: "scroll", top: "5%", paddingTop: 15 }}
        >
          <Box className="delete-modal-container">
            <Box
              className=""
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: 17,
                  // ml: 4,
                  color: COLORS.gray70,
                  fontWeight: "bold",
                }}
              >
                Edit Contact Details
              </Typography>
              <Box className="edit-modal-box">
                <IconContext.Provider value={{ size: 15 }}>
                  <RxCross1
                    onClick={() => {
                      setEditModal(false);
                    }}
                  />
                </IconContext.Provider>
              </Box>
            </Box>

            {/* <Box className="edit-line" /> */}
            <Divider />
            <Box className="contact-detail-modal-scroll">
              <Box sx={{ p: 1 }}>
                {inputList.map((val, index) => {
                  return (
                    <>
                      <Typography
                        style={{ fontSize: 15, color: COLORS.gray50 }}
                      >
                        {val.label}
                      </Typography>
                      {val.type == "input" && val.ID != "mobile" && (
                        <CustomInput
                          error={val.emptyFlag}
                          Value={inputList[index].value}
                          onChange={(e) => {
                            let temp = [...inputList];
                            temp[index].value = e.target.value;
                            let mobileInd = temp.findIndex(
                              (val) => val.ID == "mobile"
                            );
                            if (val.ID == "email") {
                              var regex = /\S+@\S+\.\S+/;
                              if (regex.test(e.target.value)) {
                                temp[index].valid = true;
                                temp[mobileInd].require = false;
                                temp[mobileInd].emptyFlag = "NM";
                              } else {
                                temp[index].valid = false;
                                temp[mobileInd].require = true;
                                temp[mobileInd].emptyFlag = false;
                              }
                            }

                            if (
                              temp[index].value == "" &&
                              temp[index].require == true
                            ) {
                              temp[index].emptyFlag = true;
                            } else {
                              temp[index].emptyFlag = false;
                            }
                            if (temp[index].value) {
                              temp[index].emptyFlag = false;
                            }
                            setInputList(temp);
                          }}
                        />
                      )}
                      {val.ID == "mobile" && (
                        <Box sx={{ width: "100%", mb: 2 }}>
                          <div
                            // sx={{
                            //   display: "flex",
                            //   flexDirection: "row",
                            //   justifyContent: "space-between",
                            // }}
                            className="mobile-container"
                          >
                            <CustomInput
                              error={countryCode.emptyFlag}
                              Placeholder="Code"
                              Value={"+" + countryCode.value}
                              Style={{ width: "100%" }}
                              onChange={(e) => {
                                let temp = { ...countryCode };
                                let tempIn = [...inputList];
                                temp.value = e.target.value.replace(/^\+/, "");
                                if (
                                  !validCountryCodes.some(
                                    (val) =>
                                      val.phone ==
                                      e.target.value.replace(/^\+/, "")
                                  )
                                ) {
                                  temp.emptyFlag = true;
                                } else {
                                  temp.emptyFlag = false;
                                  let cData = validCountryCodes.find(
                                    (val) =>
                                      val.phone ==
                                      e.target.value.replace(/^\+/, "")
                                  );
                                  if (
                                    cData &&
                                    cData.phoneLength == val.value.length
                                  ) {
                                    let emailInd = tempIn.findIndex(
                                      (val) => val.ID == "email"
                                    );
                                    tempIn[emailInd].require = false;
                                    tempIn[emailInd].emptyFlag = "NM";
                                  }
                                }
                                if (val.value != "") {
                                  let phone = val.value.replace(/[^0-9]/g, "");
                                  let countryData = validCountryCodes.find(
                                    (val) =>
                                      val.phone ==
                                      e.target.value.replace(/^\+/, "")
                                  );
                                  if (countryData) {
                                    let isValid = Array.isArray(
                                      countryData?.phoneLength
                                    )
                                      ? countryData?.phoneLength.includes(
                                          phone.length
                                        )
                                      : countryData?.phoneLength ==
                                        phone.length;
                                    if (!isValid) {
                                      // if (e.target.value != "" && phone.length != 10) {
                                      tempIn[index].valid = false;
                                    } else {
                                      tempIn[index].valid = true;
                                    }
                                  }
                                }
                                setCountryCode(temp);
                                setInputList(tempIn);
                              }}
                            />
                            <CustomInput
                              error={val.emptyFlag}
                              Placeholder={val.label.split("*")[0]}
                              Value={
                                inputList[index].value.split("-").length > 1
                                  ? inputList[index].value.split("-")[1].trim()
                                  : inputList[index].value.split("-")[0].trim()
                              }
                              Style={{ width: "100%" }}
                              onChange={(e) => {
                                console.log(
                                  "jsahdkshf",
                                  inputList[index].value.split("-")[1]
                                );

                                if (countryCode.emptyFlag == true) {
                                  toast.error("Enter valid country code first");
                                } else {
                                  let temp = [...inputList];

                                  let phone = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  let countryData = validCountryCodes.find(
                                    (val) => val.phone == countryCode.value
                                  );

                                  let isValid = Array.isArray(
                                    countryData?.phoneLength
                                  )
                                    ? countryData?.phoneLength.includes(
                                        phone.length
                                      )
                                    : countryData?.phoneLength == phone.length;
                                  let emailInd = temp.findIndex(
                                    (val) => val.ID == "email"
                                  );
                                  if (
                                    temp[index].emptyFlag == "NM" &&
                                    e.target.value == ""
                                  ) {
                                    temp[emailInd].require = true;
                                    temp[emailInd].emptyFlag = false;
                                  }
                                  if (e.target.value != "" && !isValid) {
                                    // if (e.target.value != "" && phone.length != 10) {
                                    temp[index].valid = false;
                                    temp[emailInd].require = true;
                                    temp[emailInd].emptyFlag = false;
                                  } else {
                                    temp[index].valid = true;
                                    if (
                                      countryCode.emptyFlag == false &&
                                      e.target.value != ""
                                    ) {
                                      temp[emailInd].require = false;
                                      temp[emailInd].emptyFlag = "NM";
                                    }
                                  }
                                  temp[index].value = phone;
                                  if (temp[index].valid == false) {
                                    temp[index].emptyFlag = false;
                                  } else if (
                                    temp[index].value == "" &&
                                    temp[index].emptyFlag != "NM"
                                  ) {
                                    temp[index].emptyFlag = true;
                                  } else if (temp[index].emptyFlag != "NM") {
                                    temp[index].emptyFlag = false;
                                  }
                                  if (
                                    temp[index].value &&
                                    temp[index].emptyFlag != "NM"
                                  ) {
                                    temp[index].emptyFlag = false;
                                  }
                                  setInputList(temp);
                                }
                              }}
                            />
                          </div>
                          {!val.valid && (
                            <Typography
                              style={{ fontSize: 14, color: "#d32f2f" }}
                            >
                              Please enter valid mobile number
                            </Typography>
                          )}
                          {val.ID == "mobile" &&
                            countryCode.emptyFlag == true && (
                              <Typography
                                style={{ fontSize: 14, color: "#d32f2f" }}
                              >
                                Please enter valid country code
                              </Typography>
                            )}
                        </Box>
                      )}
                      {val.type == "dropdown" && (
                        <Box
                          sx={{
                            mb: 1.5,
                          }}
                        >
                          <CustomDropdown
                            menuPlacement={val?.menuPlacement}
                            Label={val.label}
                            Options={val.options}
                            Value={val.value}
                            error={val.emptyFlag}
                            OnChange={(e) => {
                              let temp = [...inputList];
                              temp[index].value = e;
                              if (
                                temp[index].value &&
                                temp[index].emptyFlag != "NM"
                              ) {
                                temp[index].emptyFlag = false;
                              } else if (temp[index].emptyFlag != "NM") {
                                temp[index].emptyFlag = true;
                              }
                              setInputList(temp);

                              if (val.ID == "state") {
                                let temp = [...inputList];
                                let allDdOptions = [...props.dd_options];

                                let indDdOptions = allDdOptions.filter(
                                  (val) => val.stateForCity == e.label
                                );
                                let ind = temp.findIndex(
                                  (val) => val.ID == "city"
                                );
                                if (indDdOptions.length == 1) {
                                  temp[ind].value = {
                                    label: indDdOptions[0].label,
                                    value: indDdOptions[0].value,
                                  };

                                  temp[ind].emptyFlag = false;
                                } else {
                                  temp[ind].value = "";
                                }
                                indDdOptions.sort(function (a, b) {
                                  var textA = a.label.toUpperCase();
                                  var textB = b.label.toUpperCase();
                                  return textA < textB
                                    ? -1
                                    : textA > textB
                                      ? 1
                                      : 0;
                                });
                                temp[ind].options = indDdOptions;
                                let tempData = [...inputList];
                                tempData[index].value = e;
                                setStateData(tempData[index]);
                                setCityList(temp[ind]);
                                setInputList(temp);
                              }
                            }}
                          />
                        </Box>
                      )}

                      {val.emptyFlag == true && (
                        <Typography style={{ fontSize: 14, color: "#d32f2f" }}>
                          Please fill {val.label}
                        </Typography>
                      )}
                    </>
                  );
                })}
              </Box>
            </Box>
            <Box className="edit-button">
              <Typography
                style={{
                  fontSize: 15,
                  color: COLORS.gray70,
                  marginRight: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEditModal(false);
                }}
              >
                Cancel
              </Typography>
              <CustomButton
                Label="Update"
                buttonColor="#1770E0"
                style={{
                  width: 80,
                  height: 25,
                  borderRadius: 5,
                }}
                labelStyle={{
                  color: "#fff",
                  fontSize: 15,
                }}
                onClick={editContactHandler}
              />
            </Box>
          </Box>
        </Modal>
        <Modal
          open={deleteModal}
          onClose={() => {
            setDeleteModal(false);
          }}
          disableAutoFocus
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          // style={{ overflowY: "scroll", top: "5%", paddingTop: 15 }}
        >
          <Box className="delete-modal-container">
            {/* <Box className="delete-modal-box">
              <IconContext.Provider value={{ size: 15, marginTop: 5 }}>
                <RxCross1
                  onClick={() => {
                    setDeleteModal(false);
                  }}
                />
              </IconContext.Provider>
            </Box> */}
            <Box
              className="delete-modal-box"
              sx={{
                cursor: "auto",
              }}
            >
              <Typography
                sx={{
                  fontSize: 18,
                  color: COLORS.gray80,
                  fontWeight: "bold",
                }}
              >
                Confirmation
              </Typography>
              <div
                style={{
                  cursor: "pointer",
                }}
              >
                <IconContext.Provider value={{ size: 15, marginTop: 5 }}>
                  <RxCross1
                    onClick={() => {
                      setDeleteModal(false);
                    }}
                  />
                </IconContext.Provider>
              </div>
            </Box>
            <Divider />

            <Typography
              sx={{
                fontSize: 15,
                color: COLORS.gray60,
                mt: 1,
              }}
            >
              Are you sure you want to delete this contact?
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                color: COLORS.gray60,
                mt: 1,
              }}
            >
              Note: This action will also remove all associated meetings for the
              contact.
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                color: COLORS.red,
                mt: 1,
              }}
            >
              Attention: Ensure you have saved any necessary data before
              proceeding.
            </Typography>

            {/* <Typography sx={{ fontSize: 15, color: COLORS.gray60, ml: 2 }}>
              Do you want to delete this contact?
            </Typography> */}
            <Box
              sx={{
                mb: 1,
                mt: 2,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: 15,
                  color: COLORS.gray70,
                  marginRight: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setDeleteModal(false);
                }}
              >
                Cancel
              </Typography>
              <CustomButton
                Label="Delete"
                buttonColor="#e63946"
                style={{
                  width: 100,
                  height: 30,
                  borderRadius: 5,
                  marginRight: "0.5%",
                }}
                labelStyle={{
                  color: "#fff",
                  fontSize: 15,
                }}
                onClick={() => {
                  deleteContact();
                }}
              />
              {/* <Box
                                className="delete-btn-box"
                                onClick={() => {
                                  deleteEmployee();
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: 15,
                                    color: COLORS.white,
                                    
                                  }}
                                >
                                  Delete
                                </Typography>
                              </Box> */}
            </Box>

            {/* <Box className="delete-btn-container">
              <Box className="delete-btn-box" onClick={deleteContact}>
                <Typography style={{ fontSize: 15, color: COLORS.white }}>
                  Delete
                </Typography>
              </Box>
            </Box> */}
          </Box>
        </Modal>
        <Modal
          open={meetingModal}
          onClose={() => {
            setMeetingModal(false);
          }}
          disableAutoFocus
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          // style={{ overflowY: "scroll", top: "5%", paddingTop: 15 }}
        >
          <Box className="delete-modal-container">
            <Box
              className=""
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: 17,
                  // ml: 4,
                  color: COLORS.gray70,
                  fontWeight: "bold",
                  ml: 2,
                }}
              >
                Add Meeting Details
              </Typography>
              <Box className="edit-modal-box">
                <IconContext.Provider value={{ size: 15 }}>
                  <RxCross1
                    onClick={() => {
                      setMeetingModal(false);
                    }}
                  />
                </IconContext.Provider>
              </Box>
            </Box>

            <Divider />
            <Box className="handle-scroll">
              <Box sx={{ p: 1 }}>
                {meetingList.map((val, index) => {
                  return (
                    <>
                      {val.type == "time" && (
                        <>
                          <Typography
                            style={{
                              fontSize: 15,
                              color: COLORS.gray50,
                            }}
                          >
                            {val.label}
                          </Typography>
                          <CustomInput
                            Type={"time"}
                            // Disabled={val.ID == "leasingMember" ? true : false}
                            error={val.emptyFlag}
                            Value={meetingList[index].value}
                            onChange={(e) => {
                              let temp = [...meetingList];
                              // if (val.ID == "meetingDate") {
                              //   temp[index].value = new Date(e.target.value)
                              //     .toJSON()
                              //     .slice(0, 10)
                              //     .split("-")
                              //     .reverse()
                              //     .join("-");
                              // } else {
                              temp[index].value = e.target.value;
                              // }

                              if (val.ID == "remarks" && [index].value == "") {
                                temp[index].emptyFlag = true;
                              } else if (
                                val.ID == "meetingDate" &&
                                (e.target.value == "" ||
                                  e.target.value.includes("NaN"))
                              ) {
                                temp[index].emptyFlag = true;
                              } else {
                                temp[index].emptyFlag = false;
                              }
                              if (temp[index].value) {
                                temp[index].emptyFlag = false;
                              }
                              setMeetingList(temp);
                            }}
                          />
                          {val.emptyFlag == true && (
                            <>
                              <Typography
                                style={{ fontSize: 15, color: COLORS.red1 }}
                              >
                                Please enter {val.label}
                              </Typography>
                            </>
                          )}
                        </>
                      )}
                      {val.type != "dropdown" && val.type != "time" && (
                        <>
                          <Typography
                            style={{
                              fontSize: 15,
                              color: COLORS.gray50,
                              marginTop: 10,
                            }}
                          >
                            {val.label}
                          </Typography>
                          <CustomInput
                            error={val.emptyFlag}
                            Value={meetingList[index].value}
                            Type={val.type == "input" ? "text" : "date"}
                            onChange={(e) => {
                              let temp = [...meetingList];
                              // if (val.ID == "meetingDate") {
                              //   temp[index].value = new Date(e.target.value)
                              //     .toJSON()
                              //     .slice(0, 10)
                              //     .split("-")
                              //     .reverse()
                              //     .join("-");
                              // } else {
                              temp[index].value = e.target.value;
                              // }

                              if (val.ID == "remarks" && [index].value == "") {
                                temp[index].emptyFlag = true;
                              } else if (
                                val.ID == "meetingDate" &&
                                (e.target.value == "" ||
                                  e.target.value.includes("NaN"))
                              ) {
                                temp[index].emptyFlag = true;
                              } else {
                                temp[index].emptyFlag = false;
                              }
                              if (temp[index].value) {
                                temp[index].emptyFlag = false;
                              }
                              setMeetingList(temp);
                            }}
                          />
                          {val.emptyFlag == true && (
                            <>
                              <Typography
                                style={{ fontSize: 15, color: COLORS.red1 }}
                              >
                                Please enter {val.label}
                              </Typography>
                            </>
                          )}
                        </>
                      )}
                      {val.type == "dropdown" && (
                        <>
                          <Typography
                            style={{
                              fontSize: 15,
                              color: COLORS.gray50,
                              marginTop: 10,
                            }}
                          >
                            {val.label}
                          </Typography>
                          <CustomDropdown
                            menuPlacement={val?.menuPlacement}
                            Label={val.label}
                            Options={val.options}
                            Value={val.value}
                            error={val.emptyFlag}
                            OnChange={(e) => {
                              let temp = [...meetingList];
                              temp[index].value = e;
                              temp[index].emptyFlag = false;
                              setMeetingList(temp);
                            }}
                          />
                          {val.emptyFlag == true && (
                            <>
                              <Typography
                                style={{ fontSize: 15, color: COLORS.red1 }}
                              >
                                Please enter {val.label}
                              </Typography>
                            </>
                          )}
                        </>
                      )}
                    </>
                  );
                })}
              </Box>
              <Box className="edit-button">
                <Typography
                  style={{
                    fontSize: 15,
                    color: COLORS.gray70,
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setMeetingModal(false);
                  }}
                >
                  Cancel
                </Typography>
                <CustomButton
                  Label="Add"
                  buttonColor="#1770E0"
                  style={{
                    width: 80,
                    height: 25,
                    borderRadius: 5,
                  }}
                  labelStyle={{
                    color: "#fff",
                    fontSize: 15,
                  }}
                  onClick={addMeetingHandler}
                />
              </Box>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={loading}
          // onClose={() => {
          //   setScanModal(false);
          // }}
          disableAutoFocus
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          // style={{ overflowY: "scroll", top: "5%", paddingTop: 15 }}
        >
          <Box className="loading-container">
            <Lottie
              className="loading-lottie"
              play
              //   speed={"1"}
              animationData={loadingAnime}
            />
          </Box>
        </Modal>
      </Box>
    </MainScreen>
  );
}

// export default ContactDetails;
const mapStateToProps = (state) => ({
  contact_data: state.contactData.contactDataList,
  meeting_data: state.contactData.meetingDataList,
  employee_data: state.contactData.employeeList,
  auth_email_id: state.contactData.auth_email_id,
  auth_id: state.contactData.auth_id,
  auth_name: state.contactData.auth_name,
  dd_options: state.contactData.ddOptionList,
  role: state.contactData.role,
});

export default connect(mapStateToProps, {
  ContactDataGetAction,
  MeetingDataGetAction,
  EmployeeDetailsGetAction,
  DdOptionsGetAction,
})(ContactDetails);
