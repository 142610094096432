import React, { useState, useEffect } from "react";
import "./MailHistory.css";
import {
  Box,
  Card,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Table,
  TableRow,
  Paper,
  TableBody,
  TextField,
  Tooltip,
  Checkbox,
} from "@mui/material";
import CustomInput from "../components/CustomInput/CustomInput";
import { COLORS } from "../constants/theme";
import Icons from "../constants/Icons";
import Lottie from "react-lottie-player";
import noData from "../assets/lottieFiles/noData.json";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ContactDataGetAction } from "../Redux/Action/ContactDataGetAction";
import { MailHistoryGetAction } from "../Redux/Action/MailHistoryGetAction";
import { EmployeeDetailsGetAction } from "../Redux/Action/EmployeeDetailsGetAction";
import { connect, useDispatch } from "react-redux";
import { IconContext } from "react-icons";
import { FaArrowLeftLong } from "react-icons/fa6";
import MainScreen from "../components/Navbar/MainScreen";

function MailHistory(props) {
  const navigate = useNavigate();
  const [noDataFound, setNoDataFound] = useState(false);
  const [resData, setResData] = useState([]);
  // ---------teams integration----------

  useEffect(() => {
    console.log("Asdjnaskjsa", props.mail_history);

    let temp = [...props.mail_history];
    if (temp != undefined) {
      temp.map((val) => {
        val.mailDate = new Date(val.mailDate);
        // val.mailDate = val.mailDate.toDateString();
      });
    }
    let emp = props?.employee_data.find(
      (val) => val.email_id == props.auth_email_id
    );
    let empRole;
    if (emp != undefined) {
      empRole = emp.role;
    }
    if (empRole == "admin") {
      let tempHistory = [...props.mail_history];
      tempHistory.sort(function (a, b) {
        var textA = new Date(a.mailDate);
        var textB = new Date(b.mailDate);
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
      setResData(tempHistory);
    } else {
      let tempData = temp.filter((val) => val.fromMail == props.auth_email_id);

      tempData.sort(function (a, b) {
        var textA = new Date(a.mailDate);
        var textB = new Date(b.mailDate);
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
      setResData(tempData);
    }
  }, [props.mail_history]);
  useEffect(() => {
    ContactDataGetAction();
    props.MailHistoryGetAction();
  }, [props.auth_email_id]);

  //-----------teams integration end------------------

  return (
    <MainScreen>
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingX: 1,
            pt: 3,
            pb: 2,
            ml: 2,
          }}
        >
          <Box
            sx={{
              mr: 1,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <IconContext.Provider value={{ size: 25, color: "#000" }}>
              <FaArrowLeftLong />
            </IconContext.Provider>
          </Box>
          <Typography
            style={{
              fontSize: 22,
              fontWeight: 600,
              color: "#000",
              marginRight: 10,
              textAlign: "center",
            }}
          >
            Email History
          </Typography>
        </Box>
        <Box className="mail-history-container" sx={{}}>
          <Card className="mail-history-box">
            <TableContainer
              sx={{
                maxHeight: "70vh",
                // border: "1px solid #b7b7b7",
              }}
            >
              <Table
                enableStickyHeader
                stickyHeader
                aria-label="sticky table"
                // sx={{ border: "1px solid #b7b7b7" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        border: "1px solid #fff",
                        minWidth: "39vh",
                        textAlign: "center",
                        backgroundColor: "#1770E0",
                        color: "#fff",
                        padding: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      From
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "1px solid #fff",
                        minWidth: "39vh",
                        textAlign: "center",
                        backgroundColor: "#1770E0",
                        color: "#fff",
                        padding: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      To Email
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "1px solid #fff",
                        minWidth: "39vh",
                        textAlign: "center",
                        backgroundColor: "#1770E0",
                        color: "#fff",
                        padding: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Email Date
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "1px solid #fff",
                        minWidth: "39vh",
                        textAlign: "center",
                        backgroundColor: "#1770E0",
                        color: "#fff",
                        padding: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Subject
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {resData.map((val, index) => {
                    return (
                      <tr
                        className="Row"
                        sx={{
                          "&:hover": {
                            background: "#e5e6e7",
                          },
                          backgroundColor:
                            index % 2 == 0 ? "#f2f6fb" : "#fdfefe",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          // fontSize: 24,
                        }}
                      >
                        <td
                          style={{
                            textAlign: "center",
                            fontSize: 16,
                          }}
                        >
                          <Typography
                            sx={{ width: "100%", wordWrap: "break-word" }}
                          >
                            {val.fromName} ({val.fromMail})
                          </Typography>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            fontSize: 16,
                          }}
                        >
                          <Typography
                            sx={{ width: "100%", wordWrap: "break-word" }}
                          >
                            {val.toMail}
                          </Typography>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            fontSize: 16,
                            padding: 10,
                          }}
                        >
                          <Typography
                            sx={{ width: "100%", wordWrap: "break-word" }}
                          >
                            {val.mailDate.toLocaleString()}
                          </Typography>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            fontSize: 16,
                            padding: 10,
                          }}
                        >
                          <Typography
                            sx={{ width: "100%", wordWrap: "break-word" }}
                          >
                            {val.subject}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {resData.length == 0 && (
              <Box className="no-data">
                <Lottie
                  play
                  //   speed={"1"}
                  animationData={noData}
                  style={{
                    width: 250,
                    height: 250,
                  }}
                />
                <Typography
                  sx={{ fontSize: 20, fontWeight: 500, color: "#b7beca" }}
                >
                  No Data Found
                </Typography>
              </Box>
            )}
          </Card>
        </Box>
      </Box>
    </MainScreen>
  );
}

const mapStateToProps = (state) => ({
  contact_data: state.contactData.contactDataList,
  mail_history: state.contactData.mailHistoryList,
  employee_data: state.contactData.employeeList,
  auth_email_id: state.contactData.auth_email_id,
  auth_name: state.contactData.auth_name,
});

export default connect(mapStateToProps, {
  ContactDataGetAction,
  MailHistoryGetAction,
  EmployeeDetailsGetAction,
})(MailHistory);
